.logo {
    max-width: 160px;
    /* height: 31px; */
    /* background: rgba(206, 178, 178, 0.2); */
    /* margin: 16px 24px 16px 0; */
    float: left;
}

.logo-centered {
    max-width: 160px;
    /* height: 31px; */
    /* background: rgba(206, 178, 178, 0.2); */
    /* margin: 16px 24px 16px 0; */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.round {
    padding: 0;
}

.round tbody tr {
    /* background-color: #0b1e34; */
    /* color: #fff; */
}

.dark-mode .ant-modal-mask,
.dark-mode .ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.65);
}

.bye-round {
    opacity: 0.5 !important;
}

.anticon-question-circle {
    /* cursor: pointer; */
}

.ant-input,
.ant-select-selection {
    background: none;
    /* border: 1px solid #bfe0ff; */
    font-size: 16px;
}

.ant-select-selection__placeholder,
::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(112, 112, 112) !important;
    opacity: 0.6; /* Firefox */
}

.ant-form-vertical .ant-form-item {
    padding-bottom: 0px;
}

.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
    padding-left: 12px;
}

.player-table .ant-table-tbody > tr.ant-table-row:hover > td {
    background: unset;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: rgba(0, 0, 0, 0.65);
}

#bracket-container .ant-list-item-action {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-left: 48px;
    padding: 0;
    font-size: 0;
    list-style: none;
    width: 40px;
}

.tournament-progress-bar {
    /* transition: all 150ms cubic-bezier(0.4, 0.0, 0.2, 1); */
    width: 100%;
    height: 6px;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

/* .tournament-progress-bar:hover {
	height: 30px;
} */

.round tbody tr:nth-child(even) {
    background-color: #fff;
    /* color: #fff; */
}

.round tbody tr:nth-child(odd) {
    background-color: #f7f9fa;
    /* color: #fff; */
}

/* .round tbody tr:last-child {
	border-bottom: none !important;
} */

.title-sticky-active {
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
}

.title-sticky-released {
    background-color: #fff;
    border-bottom: none;
}

/* #bracket .rounds {
	zoom: 0.2;
} */

/* .layout {
	height: 100vh;
} */

/* .layout,
#root,
#auth {
	height: 100%;
} */

.ant-modal-wrap {
    left: 0px !important;
}

.ant-menu-item {
    transition: all 100ms ease;
    border-left: 2px solid transparent;
}

.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
    -webkit-transition: opacity 0s;
    transition: opacity 0s;
}

.tabs-bg .ant-tabs-nav .ant-tabs-tab-active {
    color: #72f8ff;
}

.tabs-bg .ant-tabs-ink-bar {
    background-color: #72f8ff;
}

.tabs-bg .ant-tabs-nav .ant-tabs-tab:hover {
    color: #72f8ff;
}

.tabs-bg2 .ant-tabs-nav .ant-tabs-tab-active {
    color: #1890ff;
}

.tabs-bg2 .ant-tabs-ink-bar {
    background-color: #1890ff;
}

.tabs-bg2 .ant-tabs-nav .ant-tabs-tab:hover {
    color: #1890ff;
}

.tabs-bg-orange.ant-tabs {
    overflow-x: hidden;
    overflow-y: auto;
}

.tabs-bg-orange .ant-tabs-nav .ant-tabs-tab-active {
    color: #ffab28;
}

.tabs-bg-orange .ant-tabs-ink-bar {
    background-color: #ffab28;
}

.tabs-bg-orange .ant-tabs-nav .ant-tabs-tab:hover {
    color: #ffab28;
}

.ant-tabs.no-scroll .ant-tabs-nav-container-scrolling {
    padding: 0px !important;
}

.ant-tabs.no-scroll .ant-tabs-tab-arrow-show {
    display: none;
}

.ant-layout-sider-dark .ant-menu-item:hover {
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.ant-layout-sider-dark .ant-menu-item-active,
.products-menu .ant-menu-item-active {
    background-color: rgba(255, 255, 255, 0.04) !important;
    /* border-left: 2px solid #1890ff; */
}

.ant-modal-body {
    overflow-x: auto;
}

.ant-layout-sider-dark .ant-menu-item-selected,
.products-menu .ant-menu-item-selected {
    /* background-color: rgba(255, 255, 255, 0.1) !important; */
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-left: 2px solid #1890ff !important;
}

.ant-menu-item-active {
    background-color: rgba(255, 255, 255, 0.04) !important;
    /* border-left: 2px solid #1890ff; */
}

.ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.1) !important;
    /* background-color: rgba(0, 0, 0, 0.5) !important; */
    border-left: 2px solid #1890ff !important;
}

.table_nowrap table th,
.table_nowrap table td {
    white-space: nowrap;
}

body.light-mode .match-table.table-striped-rows tr.completed:nth-child(2n) td {
    background-color: #fff9e1;
}

body.light-mode .match-table.table-striped-rows tr.in_progress:nth-child(2n) td {
    background-color: #e4f1ff;
}

body.light-mode .match-table.table-striped-rows tr.streaming:nth-child(2n) td {
    background-color: #ffe5e6;
}

body.light-mode .match-table.table-striped-rows tr.streaming.pending td {
    background-color: #aee8e6;
}

body.light-mode .match-table.table-striped-rows tr.pending td {
    background-color: #aee8e6;
}

body.dark-mode .match-table.table-striped-rows tr.completed:nth-child(2n) td {
    background-color: #17212b;
}

body.dark-mode .match-table.table-striped-rows tr.in_progress:nth-child(2n) td {
    background-color: #08213b;
}

body.dark-mode .match-table.table-striped-rows tr.streaming:nth-child(2n) td {
    background-color: #3c1315;
}

body.dark-mode .match-table.table-striped-rows tr.streaming.pending td {
    background-color: #002932;
}

body.dark-mode .match-table.table-striped-rows tr.pending td {
    background-color: #002932;
}

.ant-card-body {
    flex: 1;
}

.ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
.ant-tabs-tab-next.ant-tabs-tab-arrow-show {
    color: #fff;
}

.ant-card-meta-title {
    white-space: normal;
}

.ant-card-hoverable2 {
    /* border: 1px solid rgba(0, 0, 0, 0.1) !important; */
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.ant-card-hoverable2:hover {
    /* z-index: 1; */
    transform: translateY(-0.5rem);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 15px 37px 0 #dbe2eb !important;
    /* box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.1), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
		0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important; */
}

.ant-card-hoverable.dashed-card {
    border: 2px dashed #ccc !important;
}

.ant-card-hoverable.selected-card {
    border: 2px solid #198fff !important;
}

.ant-card-hoverable {
    /* border: 1px solid rgba(0, 0, 0, 0.1) !important; */
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: scale(0.98) translateZ(0px);
}

.ant-card-hoverable:hover {
    backface-visibility: hidden;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 16px 20px -10px, #dbe2eb 0px 16px 10px -10px;
    cursor: pointer;
    display: block;
    position: relative;
    transform: scale(1) translateZ(0px);
    /* border: 1px solid #198fff; */
    /* transition-duration: 300ms; */
    /* transition-property: transform, box-shadow; */
    /* transition-timing-function: ease-out; */
    z-index: 10;
    border-radius: 4px;
}

@media only screen and (min-width: 960px) {
    .section-header h2,
    .section-header h3 {
        letter-spacing: 8px;
    }

    .ant-page-header-heading-title {
        letter-spacing: 5px;
    }

    /* h4.ant-typography,
	.ant-typography h4 {
		color: rgba(0, 0, 0, 0.85);
		font-weight: 600;
		font-size: 16px !important;
		line-height: 1.4;
		text-transform: uppercase;
		letter-spacing: 3px;
	} */
}

.ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: #ffffff;
}

/* .ant-tabs {
	height: 100%;
} */

.ant-tabs-bar {
    margin: 0;
}

.ant-page-header-heading-title {
    font-size: 22px;
    font-weight: 500;
}
/* #root,
#auth {
	height: 100%;
} */

.products-menu {
    position: fixed;
    left: 0px;
    width: 58px;
    z-index: 10;
    height: 100%;
    overflow: hidden;
}

/* .products-menu {
	position: absolute;
	left: 0px;
	width: 50px;
	z-index: 1;
} */

.ant-menu-dark {
    background: none !important;
}

.ant-menu-light {
    background: none !important;
}

.ant-menu-horizontal,
.ant-menu-horizontal .ant-menu-submenu,
.ant-menu-horizontal .ant-menu-submenu:hover {
    border: none;
}

.logged-out .mobile .ant-message {
    width: 300px;
    left: 50%;
    transform: translateX(-50%);
    top: initial;
    position: fixed;
}

.logged-in .mobile .ant-message {
    width: 300px;
    left: 50%;
    transform: translateX(-50%);
    top: initial;
    position: fixed;
}

.logged-out .desktop .ant-message {
    width: 300px;
    left: 50%;
    transform: translateX(-50%);
    top: initial;
    position: fixed;
}

.sidebar {
    height: 100%;
}

.logged-in .desktop .ant-message {
    width: 300px;
    left: calc(50% + 150px);
    transform: translateX(-50%);
    top: initial;
    position: fixed;
}

.ant-descriptions-title {
    padding-bottom: 6px;
    border-bottom: 1px solid #e8e8e8;
}

.ant-form-horizontal .ant-form-item-label {
    line-height: 39.9999px;
}

/* .ant-input {
	text-align: left !important;
} */

.noselect .ant-table-tbody > tr.ant-table-row:hover > td {
    background: unset !important;
    cursor: auto !important;
}

/* .ant-message-notice {
	display: flex;
	justify-content: flex-start;
} */

.ant-list-item-meta-title {
    margin-bottom: 0px;
}

.ant-list-item.selected {
    background-color: #e8e8e8;
}

.ant-layout-sider-collapsed {
    min-width: 60px !important;
    max-width: 60px !important;
    width: 60px !important;
}

.ant-menu-inline-collapsed {
    min-width: 60px !important;
    max-width: 60px !important;
    width: 60px !important;
    padding: 0px;
}

.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0px 20px !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: none;
}

.bracket-compact,
.bracket-default {
    background-color: #dff4ff !important;
    /* background-color: #e4f4fd !important; */
}

.bracket-compact.dark,
.bracket-default.dark {
    background-color: #0b1a2c !important;
}

.bracket-surface .segment {
    /* display: flex; */
    /* background-color: #000; */
}

.bracket-surface .segment-title {
    background-color: #000;
}

.ant-breadcrumb {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    /* margin: 12px 0px !important; */
}

.ant-tabs .ant-tabs-top-content,
.ant-tabs .ant-tabs-bottom-content {
    width: 100%;
    height: 100%;
}

.ant-tabs-tabpane {
    /* height: 100%; */
}

.products-menu .ant-badge-count {
    min-width: 16px;
    height: 16px;
    padding: 3px;
    font-size: 12px;
    line-height: 10px;
    box-shadow: none;
    font-weight: 700;
}

/* .dark-breadcrumb {
	background-color: #0d1e35;
} */

.orange-breadcrumb .ant-breadcrumb-link a {
    color: #e47e12;
}

.orange-breadcrumb.ant-breadcrumb-link a:hover {
    color: #e87400;
}

.orange-breadcrumb.ant-breadcrumb > span:last-child a {
    color: #e87400;
    font-weight: bold;
}

.orange-breadcrumb .ant-breadcrumb-separator {
    color: #faad14;
}

.dark-breadcrumb .ant-breadcrumb-link a {
    color: rgba(255, 255, 255, 0.7);
}

.dark-breadcrumb .ant-breadcrumb-link a:hover {
    color: #fff;
}

.dark-breadcrumb.ant-breadcrumb > span:last-child a {
    color: #fff;
    font-weight: 500;
}

.dark-breadcrumb .ant-breadcrumb-separator {
    color: rgba(255, 255, 255, 0.5);
}

.ant-list-item-meta {
    align-items: center;
}

.ant-badge-dot {
    /* width: 8px; */
    /* height: 8px; */
    box-shadow: none;
}

.ant-menu-item.deleted {
    color: rgba(255, 255, 255, 0.35) !important;
    opacity: 0.8;
}

.player-search.ant-select-auto-complete.ant-select .ant-select-selection__placeholder,
.player-search.ant-select-selection__placeholder ::placeholder {
    color: #000 !important;
}

.player-search {
    margin: 5px;
    /* max-width: 200px; */
}

.player-search input {
    color: #000;
}

.player-search .ant-select-selection {
    background: none !important;
    color: #000;
}

.player-search ::placeholder {
    color: #000 !important;
    opacity: 1;
}

.player-search .ant-input-search-icon {
    color: #000;
}

.products-menu .ant-menu-item {
    /* padding: 0px 10px; */
}

.ant-badge-count {
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    font-size: 12px;
    line-height: 18px;
    box-shadow: none;
}

body.light-mode .match-table .in_progress {
    background-color: #d3e8ff;
}

body.light-mode .match-table .completed {
    background-color: #fffdf7;
}

body.light-mode .match-table .streaming {
    background-color: #ffe5e6;
}

body.dark-mode .match-table .in-progress {
    background-color: #08213b;
}

body.dark-mode .match-table .streaming {
    background-color: #3c1315;
}

/* .ant-descriptions-item-label {
	font-weight: normal !important;
}

.ant-descriptions-item-content {
	font-weight: bold;
} */
/* .ant-message {
	left: 125px;
} */

.ant-layout-sider {
    background: #0d1e35;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
    padding: 12px 24px;
}

.ant-avatar {
    /* background-color: #e8e8e8; */
}

.ant-table-pagination.ant-pagination {
    margin: 16px;
}

.ant-layout-header-dark .ant-input-affix-wrapper .ant-input {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    padding-left: 15px;
    border-radius: 30px;
}

.ant-layout-header-dark .ant-input-search-icon {
    color: #fff;
}

.ant-layout-header-light .ant-input-affix-wrapper .ant-input {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #000;
    padding-left: 15px;
    border-radius: 30px;
}

.ant-layout-header-light .ant-input-search-icon {
    color: #000;
}

/* .ant-layout-sider .ant-menu.ant-menu-dark .ant-menu-item {
	border-left: 2px solid transparent;
} */

.ant-menu-submenu-title .anticon {
    margin-right: 15px;
}

.ant-layout-sider .ant-menu-sub .ant-menu-item {
    padding-left: 24px !important;
}

.Tappable-inactive {
    display: inline-block;
}

/* .ant-menu.ant-menu-dark .ant-menu-item-selected {
	background: rgba(255, 255, 255, 0.08);
} */

.ant-layout-sider .ant-menu.ant-menu-dark .ant-menu-item-selected {
    background: rgba(255, 255, 255, 0.08);
    border-left: 2px solid #1890ff !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
    border-left: 2px solid transparent !important;
}

.ant-layout-sider.products-menu .ant-menu.ant-menu-dark .ant-menu-item-selected {
    background: none;
    border: none;
}

.ant-layout-sider.products-menu {
    /* background-color: #00061d; */
    background-color: #081629;
    box-shadow: inset -5px 0 10px -2px rgba(0, 0, 0, 0.4);
    /* border-right: 1px solid rgba(255, 255, 255, 0.15); */
}

.ant-layout-header .ant-btn {
    padding: 0px 10px;
}

.ant-btn {
    line-height: 1;
    padding: 0px 10px;
}

.ant-btn + .ant-btn {
    margin-left: 6px;
}

.ant-form-item {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper,
.ant-form-inline .ant-form-item > .ant-form-item-label {
    vertical-align: middle;
}

.ant-form-item-control {
    line-height: 20px;
}

.ant-form-vertical .ant-form-item-label {
    padding: 8px 0px 0px;
}

.ant-form-item-label {
    line-height: 1em;
}

.ant-table-tbody > tr {
    cursor: pointer;
}

.ant-layout-header-dark {
    /* background: linear-gradient(180deg, #21344c, #001529); */
    /* background: linear-gradient(180deg, #354358, #0d1e35); */
    /* background: linear-gradient(180deg, #090b17, #0d1e35); */
    background: linear-gradient(180deg, #26394a, #0b192b);
    /* box-shadow: 0 -2px 10px 0 rgba(0, 21, 41, .35); */
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 10px 0px;
    z-index: 1;
}

.header-narrow {
    /* background: linear-gradient(180deg, #21344c, #001529); */
    background: linear-gradient(180deg, #354358, #0d1e35);
    /* box-shadow: 0 -2px 10px 0 rgba(0, 21, 41, .35); */
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 10px 0px;
    z-index: 1;
}

.ant-layout-header-light {
    background: linear-gradient(180deg, #fff, #fafafa);
    box-shadow: 0 -2px 10px 0 rgba(0, 21, 41, 0.35);
    z-index: 1;
}

.ant-descriptions-item {
    vertical-align: top;
}

.ant-layout-content {
    /* min-height: auto; */
    /* display: flex; */
    /* flex-direction: column; */
}

.ant-descriptions-item-label {
    font-weight: bold;
}

.ant-drawer-content {
    /* background-color: #0d1e35; */
}

/* .ant-descriptions-item-content.nopadding {
	padding: 0px !important;
} */

.ant-page-header-heading-extra {
    position: relative;
}

.ant-page-header {
    padding: 24px;
}

.ant-content {
    padding: 24px;
    flex: 1;
    display: flex;
}

/* .league-manager .ant-btn-primary {
	background: #ff8a29;
	border-color: #fe7925;
	box-shadow: 0 5px 15px rgba(0, 63, 255, .2);
	line-height: 1em;
} */

.ant-btn-primary {
    /* background: linear-gradient(0deg, rgb(0, 109, 255), rgb(0, 210, 254)) no-repeat; */
    /* border: none; */
    box-shadow: 0 5px 15px rgba(0, 63, 255, 0.2);
    /* line-height: 1em; */
}

.ripple-effect {
    width: 100%;
    position: relative;
    display: flex;
    overflow: hidden;
    height: 100%;
}

.ant-empty {
    flex: 1;
    align-self: center;
}

.ant-menu-submenu-title {
    padding: 0px;
}

.ellipsis {
    /* width: 150px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.dark .player-list .ant-list-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* 
.player-list .ant-list-items {
	overflow: auto;
	max-height: 700px;
} */

/* .ant-row {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.ant-col:first-child {
	padding-left: 0 !important;
}

.ant-col:last-child {
	padding-right: 0 !important;
} */

.ant-col {
    padding-top: 8px;
    padding-bottom: 8px;
}

body.light-mode .table-striped-rows tr:nth-child(2n) td {
    background-color: #f7f9fa;
}
body.light-mode .table-striped-rows thead {
    background-color: #f1f1f1;
}

body.dark-mode .table-striped-rows tr:nth-child(2n) td {
    background-color: #071524;
}
body.dark-mode .table-striped-rows thead {
    background-color: #071524;
}

.masonry-container {
    width: 100%;
    margin: auto;
}

.masonry-container .masonry {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: stretch;
    width: 100%;
    margin: auto;
}

.masonry-container .column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: stretch;
    flex-grow: 1;
    /* max-width: 100%; */
    min-width: 200px;
    max-width: 400px;
}

.masonry-container .item + .item {
    margin-top: 14px;
}

@media only screen and (max-width: 600px) {
    .masonry-container .column {
        min-width: 100%;
    }
}

@media only screen and (max-width: 600px) and (min-width: 960px) {
    .masonry-container .column {
        min-width: 50%;
    }
}

@media only screen and (min-width: 960px) {
    .masonry-container .column {
        min-width: calc(33% - 10px);
    }
}

@media only screen and (min-width: 1280px) {
    .masonry-container .column {
        min-width: calc(25% - 10px);
    }
}

@media only screen and (min-width: 1920px) {
    .masonry-container .column {
        min-width: calc(20% - 10px);
    }
}

.masonry-container .column {
    margin-left: 7px;
    margin-right: 7px;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.headroom {
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
    transform: translate3d(0px, 0px, 0px);
    justify-content: center;
    display: flex;
    background: linear-gradient(-90deg, #002eaf, #008dd0) 100% 100% no-repeat;
}
/* 
.headroom {
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
}
.headroom--unfixed {
	position: relative;
	transform: translateY(0);
}
.headroom--scrolled {
	transition: transform 200ms ease-in-out;
}
.headroom--unpinned {
	position: fixed;
	transform: translateY(-100%);
}
.headroom--pinned {
	position: fixed;
	transform: translateY(0%);
} */

.headroom--pinned.headroom--scrolled {
    z-index: 10 !important;
}

.scroll-container {
    -webkit-overflow-scrolling: touch;
}

.scrolling-wrapper-container {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    width: 100%;
}

.scrollto-top-container {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    cursor: pointer;
    opacity: 0.6;
    text-align: center;
    transition: all 300ms ease;
}

.scrollto-top-container:hover {
    opacity: 1;
    animation: wiggle 1s ease;
    animation-iteration-count: 1;
}

@keyframes wiggle {
    20% {
        transform: translate(-50%, 6px);
    }
    40% {
        transform: translate(-50%, -6px);
    }
    60% {
        transform: translate(-50%, 4px);
    }
    80% {
        transform: translate(-50%, -2px);
    }
    100% {
        transform: translate(-50%, 0);
    }
}

.ant-table-row.winners + .ant-table-row.losers > td {
    border-top: 1px solid #ff838a !important;
}

.display-linebreak {
    white-space: pre-line;
}

.border-gradient {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-radius: 50%;
}
.border-gradient-purple {
    border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
}
.border-gradient-green {
    border-image-source: linear-gradient(to left, #00c853, #b2ff59);
}

.ant-upload.ant-upload-select {
    display: block;
}

.gradient-border {
    background: linear-gradient(116deg, #70ceff 0%, #0380f5 100%);
    border-radius: 50%;
    padding: 1px;
    position: relative;
}

.match-disabled {
    opacity: 0.7;
}

.ball-tray .ant-btn + .ant-btn {
    margin-left: 0px;
}

.ball-scored {
    opacity: 0.3 !important;
}

.stream-overlay-button .ant-btn-ghost {
    color: #fff;
}

.footer-dark {
    text-align: center;
    background: #111b27;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.65);
}

.footer-light {
    text-align: center;
    background: none;
}

.slick-slider {
    background-color: #000;
}

.slick-slide img {
    margin: auto;
}

.slick-next,
.slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translate(0, -50%);
    cursor: pointer;
    color: red;
    border: none;
    outline: 0;
    background: 0 0;
}

.slick-prev {
    left: 25px;
    z-index: 100;
}

.slick-next {
    right: 25px;
    z-index: 100;
}

.slick-dots {
    bottom: 15px;
    color: #fff;
    z-index: 100;
}

.slick-dots li button:before {
    color: #fff;
}

.slick-dots li.slick-active button:before {
    color: #fff;
}

.image-button-container {
    position: relative;
    display: flex;
    justify-content: center;
}

.image-button-container:hover .image-button-overlay {
    opacity: 1;
}

.image-button-container img {
    transition: 0.5s ease;
}

.image-button-container:hover img {
    opacity: 0.5;
}

.image-button-overlay {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;
}

.footer-dark {
    text-align: center;
    background: #111b27;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.65);
}

.footer-light {
    text-align: center;
    background: none;
}

.player-wrapper .video-react .video-react-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.5);
    opacity: 0.5; */
}

.player-wrapper .video-react.video-react-has-started .video-react-video {
    /* opacity: 1; */
}

.player-wrapper .video-react .video-react-big-play-button {
    font-size: 3em;
    line-height: 1.5em;
    height: 1.5em;
    width: 3em;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border: none;
    background: #3b95fa;
    border-radius: 0.3em;
    transition: all 0.4s;
    transform: translate(-50%, -50%);
}
.player-wrapper .video-react:hover .video-react-big-play-button {
    background-color: #3b95fa;
    border: none;
}

.ant-btn.disabled,
.disabled .ant-btn {
    color: rgba(255, 255, 255, 0.25) !important;
    border-color: rgba(255, 255, 255, 0.25) !important;
}

.live-scoring.pending {
    opacity: 0.5;
}

.live-text {
    font-size: 11px;
    padding-left: 5px;
    padding-bottom: 5px;
    font-weight: 700;
    letter-spacing: 2px;
    color: #f5212d;
}

.ant-modal .live-text {
    font-size: 11px;

    font-weight: 700;
    letter-spacing: 2px;
    color: #f5212d;
}

.completed-text {
    font-size: 11px;
    padding-left: 5px;
    padding-bottom: 5px;
    font-weight: 700;
    letter-spacing: 2px;
    color: #999;
}

.ant-modal .completed-text {
    font-size: 11px;

    font-weight: 700;
    letter-spacing: 2px;
    color: #999;
}

.pending-text {
    font-size: 11px;
    padding-left: 5px;
    padding-bottom: 5px;
    font-weight: 700;
    letter-spacing: 2px;
    /* color: #ff7b1b; */
    color: #0db9aa;
}

.dark-mode .ant-table-row.pending {
    background-color: #002932;
}

.light-mode .ant-table-row.pending {
    background-color: #aee8e6;
}

.ant-modal .pending-text {
    font-size: 11px;

    font-weight: 700;
    letter-spacing: 2px;
    /* color: #ff7b1b; */
    color: #0db9aa;
}

.scheduled-text {
    font-size: 11px;
    padding-left: 5px;
    padding-bottom: 5px;
    font-weight: 700;
    letter-spacing: 2px;
    color: #8bc24a;
}

.ant-modal .scheduled-text {
    font-size: 11px;

    font-weight: 700;
    letter-spacing: 2px;
    color: #8bc24a;
}

#bracket-container .dark .ant-progress-text {
    color: rgba(255, 255, 255, 0.7) !important;
}

#bracket-container .ant-progress-text {
    color: rgba(0, 0, 0, 0.65) !important;
}

.player-search-clear.ant-select-auto-complete.ant-select .ant-input {
    border: 1px solid rgba(255, 255, 255, 0.7);
}

.youtube-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 20px;
}

.youtube-container iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.ant-comment-inner {
    padding: 16px 0px 0px 0px;
}

.feed-comment .ant-input-group-addon {
    padding: 0px;
}

.ant-comment-content {
    font-size: 16px;
}

.ant-comment-content p {
    margin-bottom: 0px;
}

.ant-comment-actions {
    margin-top: 5px;
}

.mobile.dark {
    background-color: #000 !important;
}

.mobile.light {
    background-color: #fff !important;
}

.dark-mode .blinking {
    animation: blinkingTextLight 1.2s infinite;
}

.light-mode .blinking {
    animation: blinkingTextDark 1.2s infinite;
}

.timer-wrapper {
    display: flex;
    justify-content: center;
}

.timer-wrapper .timer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timer-wrapper .text {
    color: #aaa;
}

.timer-wrapper .value {
    font-size: 20px;
    font-weight: 700;
}

.timer-wrapper .info {
    max-width: 360px;
    margin: 40px auto 0;
    text-align: center;
    font-size: 16px;
}

.marquee {
    /* top: 6em; */
    position: relative;
    box-sizing: border-box;
    animation: marquee 60s linear infinite;
    /* z-index: 0; */
}

.slick-track {
    display: flex;
    align-items: center;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 8px 16px;
    padding-left: 40px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}
.ripple::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #ccc 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
}
.ripple:active::after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
}

.arrow-right {
    width: 0;
    height: 0;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 40px solid #fff;
    position: absolute;
    left: 0;
}

.arrow-right-tiny {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #fff;
    position: absolute;
    left: 0;
}

.arrow-left {
    width: 0;
    height: 0;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-right: 40px solid #fff;
    position: absolute;
    right: 0;
}

.arrow-left-tiny {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #fff;
    position: absolute;
    right: 0;
}

.arrow-right-small {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #fff;
    position: relative;
    left: 0;
}

.arrow-left-small {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #fff;
    position: relative;
    right: 0;
}

/* .ant-message-notice-content {
    padding: 0px;
} */

@media print {
    @page {
        size: landscape;
    }
}

/* .marquee:hover {
    animation-play-state: paused;
} */

/* Make it move! */
@keyframes marquee {
    0% {
        transform: translateY(100vh);
    }
    100% {
        transform: translateY(-100%);
    }
}

@keyframes blinkingTextLight {
    0% {
        color: #fff;
        background-color: inherit;
        border-color: none;
    }
    49% {
        color: #fff;
        background-color: inherit;
        border-color: none;
    }
    60% {
        color: transparent;
        background-color: none;
        border-color: none;
    }
    99% {
        color: transparent;
        background-color: none;
        border-color: none;
    }
    100% {
        color: #fff;
        background-color: inherit;
        border-color: none;
    }
}

@keyframes blinkingTextDark {
    0% {
        color: #000;
        background-color: inherit;
        border-color: none;
    }
    49% {
        color: #000;
        background-color: inherit;
        border-color: none;
    }
    60% {
        color: transparent;
        background-color: inherit;
        border-color: none;
    }
    99% {
        color: transparent;
        background-color: inherit;
        border-color: none;
    }
    100% {
        color: #000;
        background-color: inherit;
        border-color: none;
    }
}
