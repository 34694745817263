.dpl .hello h1 {
	color: #0b2296;
	font-size: 64px;
	font-size: 4rem;
	margin: 0;
}
.dpl .hello .logo {
	width: 250px;
}
.dpl .header-container {
	background-attachment: fixed;
	background-color: #161922;
	background-image: url(../../assets/img/dpl/header-bg3.jpg);
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: cover;
}

.dpl .hidden {
	display: none;
}
/* body,
html {
	border: 0;
	font-family: 'Exo 2', sans-serif;
	font-size: 16px;
	margin: 0;
	overflow-x: hidden !important;
	padding: 0;
} */
/* body li,
html li {
	font-size: 32px;
	font-size: 2rem;
} */
/* .wrapper {
	max-width: 100% !important;
	overflow-x: hidden !important;
} */
.dpl .content-container {
	margin-top: -7px;
}
@media only screen and (min-width: 768px) {
	.dpl .content-container {
		margin: 0;
	}
}
.dpl .container {
	max-width: 1120px;
}
.dpl .flex {
	-ms-flex: 1;
	-ms-flex-direction: column;
	-ms-flex-pack: start;
	-webkit-box-direction: normal;
	-webkit-box-flex: 1;
	-webkit-box-orient: vertical;
	-webkit-box-pack: start;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-start;
}
@media only screen and (min-width: 768px) {
	.dpl .flex {
		-ms-flex: 1;
		-ms-flex-direction: row;
		-ms-flex-pack: justify;
		-webkit-box-direction: normal;
		-webkit-box-flex: 1;
		-webkit-box-orient: horizontal;
		-webkit-box-pack: justify;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: space-between;
	}
}
.dpl .row {
	margin-bottom: 0;
}
.dpl .row .col {
	padding: 0;
}
.dpl .col-3 {
	display: inline-block;
}
.dpl .header-full {
	display: none;
}
@media only screen and (min-width: 768px) {
	.dpl .col-3 {
		-ms-flex: 1;
		-ms-flex-direction: row;
		-ms-flex-line-pack: start;
		-ms-flex-pack: justify;
		-webkit-box-direction: normal;
		-webkit-box-flex: 1;
		-webkit-box-orient: horizontal;
		-webkit-box-pack: justify;
		align-content: flex-start;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: space-between;
	}
	.dpl .col-3 .col + .col {
		margin-left: 60px;
	}
	.dpl .col-3 .row .col {
		padding: 0 2rem;
	}
}
.dpl:after {
	background: #161922 url(../../assets/img/dpl/header-bg3.jpg) 0 0;
	background-size: cover;
	content: "";
	height: 100vh;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: -1;
}

.dpl .header-container-sub {
	height: auto;
	position: relative;
}
@media only screen and (min-width: 768px) {
	.dpl .header-container-sub {
		min-height: 100px;
	}
}
@media only screen and (min-width: 768px) {
	.dpl .header-container-sub .header-logo {
		display: inline-block;
		margin: 0;
	}
	.dpl .header-container-sub .container:first-of-type {
		padding-bottom: 85px;
		padding-top: 85px;
	}
}
.dpl .header-container-sub .top-nav {
	display: none;
}
.dpl .header-container {
	height: auto;
	position: relative;
}
@media only screen and (min-width: 768px) {
	.dpl .header-container {
		height: 700px;
	}
}
.dpl .header-container .container {
	text-align: center;
}
.dpl .header-container .container:first-of-type {
	-ms-flex-align: center;
	-ms-flex-line-pack: center;
	-ms-flex-pack: center;
	-webkit-box-align: center;
	-webkit-box-pack: center;
	align-content: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	max-width: 1120px;
	padding-top: 40px;
	position: relative;
}
@media only screen and (min-width: 768px) {
	.dpl .header-container .container:first-of-type {
		-ms-flex-pack: justify;
		-webkit-box-pack: justify;
		justify-content: space-between;
	}
	.dpl .header-container .container {
		text-align: left;
	}
}
.dpl .header-container .header-logo {
	display: inline-block;
}
@media only screen and (min-width: 768px) {
	.dpl .header-container .header-logo {
		display: inline-block;
		margin: 0;
	}
	.dpl .header-container .container:first-of-type {
		padding-top: 85px;
	}
}
.dpl .header-container .top-nav {
	display: none;
}
.dpl .header-container .header-app {
	bottom: 0;
	display: inline-block;
	line-height: 0;
	position: absolute;
	z-index: 1;
}
.dpl .header-container .header-app img {
	max-width: 700px;
}
@media only screen and (min-width: 768px) {
	.dpl .header-container .header-app {
		bottom: 0;
		display: inline-block;
		line-height: 0;
		position: absolute;
		z-index: 1;
	}
	.dpl .header-container .header-app img {
		max-width: 1100px;
	}
}
@media only screen and (min-width: 768px) {
	.dpl .header-container .text-container {
		top: 30px;
	}
}
.dpl .header-container .text-container {
	max-width: 500px;
	position: relative;
	top: 10px;
	z-index: 2;
}
.dpl .header-container .text-container h1 {
	color: #fff;
	/* font-family: 'Exo 2', sans-serif; */
	font-size: 50px;
	line-height: 60px;
}
.dpl .header-container .text-container .notify-container {
	display: inline-block;
	width: 360px;
}
.dpl .header-container .text-container .notify-container h3 {
	color: #ffad23;
	font-size: 25px;
}
.dpl .header-container .text-container .notify-container p {
	color: #fff;
	font-size: 16px;
}
.dpl .header-container .text-container .notify-container form {
	margin-top: 30px;
}
.dpl .tos {
	padding-bottom: 40px;
	padding-top: 40px;
}
.dpl .tos h2 {
	font-size: 3em;
}
.dpl .tos h3 {
	font-size: 1.4em;
	font-weight: 800;
	line-height: 1.4em;
}
.dpl form {
	display: inline-block;
}
.dpl form .fields {
	-ms-flex: 1;
	-ms-flex-pack: start;
	-webkit-box-flex: 1;
	-webkit-box-pack: start;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex: 1;
	justify-content: flex-start;
}
.dpl form .fields input.error {
	background-color: rgba(255, 0, 0, .16) !important;
	border: 1px solid #ff1744 !important;
	color: #ff1744 !important;
}
.dpl form .error {
	color: #ff1744;
}

.dpl .intro p {
	color: #9aa9bd;
}

.dpl .content-block {
	padding-bottom: 1rem;
	padding-top: 3rem;
	position: relative;
	width: 100%;
}
.dpl .content-block h1 {
	font-size: 35px;
	margin-top: 0;
}
.dpl .content-block h2 {
	color: #fff;
	font-size: 35px;
	margin-bottom: 10px;
	margin-top: 10px;
}
.dpl .content-block h3 {
	color: #fff;
	font-size: 35px;
	margin: 0 auto;
	max-width: 735px;
}
.dpl .content-block p {
	font-size: 15px;
	line-height: 30px;
	margin: 20px auto;
	max-width: 900px;
}
.dpl .content-block.footer {
	padding: 0;
	text-align: center;
}
.dpl .content-block.footer p {
	max-width: 100%;
}
.dpl .content-block.signup {
	text-align: center;
}
.dpl .content-block.signup h3 {
	font-size: 25px;
}
.dpl .content-block.signup h2 {
	font-size: 30px;
}
.dpl .content-block.signup form {
	text-align: center;
}
.dpl .content-block.signup form .error {
	text-align: left;
}
@media only screen and (min-width: 768px) {
	.dpl .content-block.footer {
		padding: 0;
		text-align: left;
	}
	.dpl .content-block.footer p {
		max-width: 100%;
	}
	.dpl .content-block.signup {
		padding: 3rem 0 2rem;
		text-align: left;
	}
	.dpl .content-block.signup form {
		text-align: right;
	}
}
.dpl .content-block.about {
	padding: 2rem 4rem 4rem 4rem;
}

.dpl .content-block.why {
	padding: 3rem 2rem 1rem;
}
.dpl .content-block.why h3 {
	color: #000;
	line-height: 50px;
}
@media only screen and (min-width: 768px) {
	.dpl .content-block.why {
		padding: 3rem 0 1rem;
	}
}
.dpl .content-block.ribbon {
	margin: 0 auto;
	padding: 2rem 1rem;
}
.dpl .content-block.ribbon .container {
	display: inline-block;
	margin: 0;
}
.dpl .content-block.ribbon h2 {
	color: #fff;
	/* font-family: 'Exo 2', sans-serif; */
	font-size: 26px;
	line-height: 34px;
	margin: 0;
}
.dpl .content-block.ribbon .contact-link {
	-o-transition: opacity .15s ease;
	-webkit-transition: opacity .15s ease;
	color: #fff;
	text-decoration: underline;
	transition: opacity .15s ease;
}
.dpl .content-block.ribbon .contact-link:hover {
	opacity: .75;
}
.dpl .content-block.feature {
	padding: 4rem 0;
}
.dpl .content-block.feature .col {
	text-align: center;
	width: 100%;
}
.dpl .content-block.feature .feature-label {
	color: #0089ff;
	font-size: 12px;
	letter-spacing: 1px;
	text-align: center;
	text-transform: uppercase;
}
.dpl .content-block.feature .feature-title {
	color: #000;
	line-height: 50px;
	max-width: 600px;
	text-align: center;
}
.dpl .content-block.feature .text-container .col + .col {
	margin-top: 50px;
}
.dpl .content-block.feature.how .col {
	line-height: 30px;
	/* font-family: 'Proxima Nova'; */
	font-size: 15px;
}
@media only screen and (min-width: 768px) {
	.dpl .content-block.feature .feature-label,
	.dpl .content-block.feature .feature-title {
		text-align: left;
	}
	.dpl .content-block.feature .col {
		text-align: left;
		width: 50%;
	}
	.dpl .content-block.feature .col + .col {
		margin-left: 50px;
	}
	.dpl .content-block.feature .text-container {
		-ms-flex: 1;
		-ms-flex-pack: justify;
		-webkit-box-flex: 1;
		-webkit-box-pack: justify;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		flex: 1;
		justify-content: space-between;
		max-width: 500px;
	}
	.dpl .content-block.feature .text-container .col + .col {
		margin-left: 50px;
		margin-top: 0;
	}
	.dpl .content-block.feature .text-container .col {
		margin: 0;
	}
	.dpl .content-block.feature.how .text-container {
		max-width: 100%;
	}
}
.dpl .footer-link {
	-o-transition: color .15s ease;
	-webkit-transition: color .15s ease;
	color: #039be5;
	transition: color .15s ease;
}
.dpl .footer-link:hover {
	color: #fff;
}
.dpl .align-right {
	text-align: right;
}
.dpl .notify-form {
	display: inline-block;
	margin-top: 20px;
	text-align: center;
}
@media only screen and (min-width: 768px) {
	.dpl .notify-form {
		display: inline-block;
		margin-top: 0;
		text-align: right;
	}
}
.dpl img.realtime-scoring {
	max-width: 380px;
}
.dpl img.live-brackets {
	max-width: 400px;
}
.dpl img.multiple-game-types {
	max-width: 330px;
}
.dpl img.sponsored-locations {
	max-width: 350px;
}
@media only screen and (min-width: 1024px) {
	.dpl img.realtime-scoring {
		width: 380px;
	}
	.dpl img.live-brackets {
		width: 400px;
	}
	.dpl img.multiple-game-types {
		width: 330px;
	}
	.dpl img.sponsored-locations {
		width: 350px;
	}
}
@media only screen and (min-width: 768px) {
	.dpl .intro-block {
		margin-top: -80px;
		position: relative;
		text-align: center;
	}
	.dpl .intro-block,
	.dpl .intro-block .intro-icon {
		margin-bottom: 2rem;
	}
	.dpl .intro-block h3 {
		color: #fff;
		font-size: 20px;
	}
	.dpl .intro-block p {
		line-height: 30px;
		margin: 20px auto;
	}
}
.dpl .intro-block {
	position: relative;
	text-align: center;
}
.dpl .intro-block .intro-icon {
	margin-bottom: 2rem;
}
.dpl .intro-block h3 {
	color: #fff;
	font-size: 20px;
}
.dpl .intro-block p {
	line-height: 30px;
	margin: 20px auto;
}
.dpl .centered {
	text-align: center;
	width: 100%;
}
.dpl .white-bg {
	background-color: #fff;
}
.dpl .dark-bg {
	background-color: #161922;
}
.dark-grey-bg {
	background-color: #1d2029;
}
.dpl .medium-grey-bg {
	background-color: #2b3141;
}
.dpl .light-grey-bg {
	background-color: #f9f9f9;
}
.dpl .blue-gradient-bg {
	background-image: -o-linear-gradient(left, #00d0ff 0, #213ca3 100%);
	background-image: -webkit-gradient(linear, right top, left top, from(#00d0ff), to(#213ca3));
	background-image: -o-linear-gradient(right, #00d0ff 0, #213ca3 100%);
	background-image: linear-gradient(270deg, #00d0ff 0, #213ca3);
}
.dpl .light-blue-bg {
	background-color: #f6fafe;
}
.dpl .shadow {
	-webkit-box-shadow: 0 7px 21px -7px rgba(0, 0, 0, .53);
	box-shadow: 0 7px 21px -7px rgba(0, 0, 0, .53);
}
.dpl .reverse-shadow,
.dpl .shadow {
	position: relative;
	z-index: 1;
}
.dpl .reverse-shadow {
	-webkit-box-shadow: 0 -5px 21px -7px rgba(0, 0, 0, .53);
	box-shadow: 0 -5px 21px -7px rgba(0, 0, 0, .53);
}
.dpl .orange-text {
	color: #ffad23;
}
.dpl .success-message {
	background-color: rgba(0, 0, 0, .5);
	border-radius: 5px;
	color: #fff;
	display: inline-block;
	padding: 20px;
	width: auto;
}
.dpl .hidden {
	display: none;
}
.dpl .input-button {
	-o-transition: opacity .25s ease;
	-webkit-transition: opacity .25s ease;
	border: none;
	border-radius: 5px;
	color: #fff;
	font-size: 18px;
	height: 48px;
	margin-left: 10px;
	outline-color: #ffad23;
	padding: 0 20px;
	/* text-shadow: rgba(0, 0, 0, .5) 0 -1px 0; */
	transition: opacity .25s ease;
}
.dpl .input-button.orange {
	background: #ffad23;
	background: -webkit-gradient(linear, left top, left bottom, from(#ffad23), to(#ff7e37));
	background: -o-linear-gradient(top, #ffad23 0, #ff7e37 100%);
	background: linear-gradient(180deg, #ffad23 0, #ff7e37);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffad23", endColorstr="#ff7e37", GradientType=0);
}
.dpl .input-button.disabled {
	opacity: .25;
	pointer-events: none;
}
.dpl .input-field {
	border: 1px solid hsla(0, 0%, 100%, .45) !important;
	border-radius: 5px !important;
	color: #fff !important;
	max-width: 140px;
	padding: 0 0 0 20px !important;
}
.dpl .input-field:focus {
	-webkit-box-shadow: none !important;
	border: 1px solid hsla(0, 0%, 100%, .75) !important;
	box-shadow: none !important;
}
