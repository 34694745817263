body.light-mode {
    .ant-modal-mask,
    .ant-image-preview-mask {
        background-color: rgba(0, 0, 0, 0.45);
    }
    html {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    body {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fff;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: rgba(0, 0, 0, 0.85);
    }
    abbr[title],
    abbr[data-original-title] {
        border-bottom: 0;
    }
    a {
        color: #1890ff;
        background-color: transparent;
    }
    a:hover {
        color: #40a9ff;
    }
    a:active {
        color: #096dd9;
    }
    a[disabled] {
        color: rgba(0, 0, 0, 0.25);
    }
    img {
        border-style: none;
    }
    table {
        border-collapse: collapse;
    }
    caption {
        color: rgba(0, 0, 0, 0.45);
    }
    input,
    button,
    select,
    optgroup,
    textarea {
        color: inherit;
    }
    button::-moz-focus-inner,
    [type='button']::-moz-focus-inner,
    [type='reset']::-moz-focus-inner,
    [type='submit']::-moz-focus-inner {
        border-style: none;
    }
    fieldset {
        border: 0;
    }
    legend {
        color: inherit;
    }
    mark {
        background-color: #feffe6;
    }
    ::selection {
        color: #fff;
        background: #1890ff;
    }
    // .anticon {
    //     color: rgba(0, 0, 0, 0.45);
    // }
    html {
        --antd-wave-shadow-color: #1890ff;
    }
    [ant-click-animating-without-extra-node='true']::after,
    .ant-click-animating-node {
        border-radius: inherit;
        box-shadow: 0 0 0 0 #1890ff;
        box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
    }
    .ant-alert {
        color: rgba(0, 0, 0, 0.85);
        border-radius: 2px;
    }
    .ant-alert-success {
        background-color: #f6ffed;
        border: 1px solid #b7eb8f;
    }
    .ant-alert-success .ant-alert-icon {
        color: #52c41a;
    }
    .ant-alert-info {
        background-color: #e6f7ff;
        border: 1px solid #91d5ff;
    }
    .ant-alert-info .ant-alert-icon {
        color: #1890ff;
    }
    .ant-alert-warning {
        background-color: #fffbe6;
        border: 1px solid #ffe58f;
    }
    .ant-alert-warning .ant-alert-icon {
        color: #faad14;
    }
    .ant-alert-error {
        background-color: #fff2f0;
        border: 1px solid #ffccc7;
    }
    .ant-alert-error .ant-alert-icon {
        color: #ff4d4f;
    }
    .ant-alert-close-icon {
        background-color: transparent;
        border: none;
    }
    .ant-alert-close-icon .anticon-close {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-alert-close-icon .anticon-close:hover {
        color: rgba(0, 0, 0, 0.75);
    }
    .ant-alert-close-text {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-alert-close-text:hover {
        color: rgba(0, 0, 0, 0.75);
    }
    .ant-alert-with-description {
        color: rgba(0, 0, 0, 0.85);
        border-radius: 2px;
    }
    .ant-alert-with-description .ant-alert-message {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-alert-message {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-alert-banner {
        border: 0;
        border-radius: 0;
    }
    .ant-anchor {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-anchor-wrapper {
        background-color: #fff;
    }
    .ant-anchor-ink::before {
        background-color: #f0f0f0;
    }
    .ant-anchor-ink-ball {
        background-color: #fff;
        border: 2px solid #1890ff;
        border-radius: 8px;
    }
    .ant-anchor-link-title {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-anchor-link-active > .ant-anchor-link-title {
        color: #1890ff;
    }
    .ant-select-auto-complete {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-avatar {
        color: rgba(0, 0, 0, 0.85);
        color: #fff;
        background: transparent;
        border-radius: 50%;
    }
    .ant-avatar-image {
        background: transparent;
    }
    .ant-avatar-lg {
        border-radius: 50%;
    }
    .ant-avatar-sm {
        border-radius: 50%;
    }
    .ant-avatar-square {
        border-radius: 2px;
    }
    .ant-avatar-group .ant-avatar {
        border: 1px solid #fff;
    }
    .ant-back-top {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-back-top-content {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.45);
        border-radius: 20px;
    }
    .ant-back-top-content:hover {
        background-color: rgba(0, 0, 0, 0.85);
    }
    .ant-badge {
        color: rgba(0, 0, 0, 0.85);
        color: unset;
    }
    .ant-badge-count {
        color: #fff;
        background: #ff4d4f;
        border-radius: 10px;
        box-shadow: 0 0 0 1px #fff;
    }
    .ant-badge-count a,
    .ant-badge-count a:hover {
        color: #fff;
    }
    .ant-badge-count-sm {
        border-radius: 7px;
    }
    .ant-badge-dot {
        background: #ff4d4f;
        border-radius: 100%;
        box-shadow: 0 0 0 1px #fff;
    }
    .ant-badge-status-dot {
        border-radius: 50%;
    }
    .ant-badge-status-success {
        background-color: #52c41a;
    }
    .ant-badge-status-processing {
        background-color: #1890ff;
    }
    .ant-badge-status-processing::after {
        border: 1px solid #1890ff;
        border-radius: 50%;
    }
    .ant-badge-status-default {
        background-color: #d9d9d9;
    }
    .ant-badge-status-error {
        background-color: #ff4d4f;
    }
    .ant-badge-status-warning {
        background-color: #faad14;
    }
    .ant-badge-status-pink {
        background: #eb2f96;
    }
    .ant-badge-status-magenta {
        background: #eb2f96;
    }
    .ant-badge-status-red {
        background: #f5222d;
    }
    .ant-badge-status-volcano {
        background: #fa541c;
    }
    .ant-badge-status-orange {
        background: #fa8c16;
    }
    .ant-badge-status-yellow {
        background: #fadb14;
    }
    .ant-badge-status-gold {
        background: #faad14;
    }
    .ant-badge-status-cyan {
        background: #13c2c2;
    }
    .ant-badge-status-lime {
        background: #a0d911;
    }
    .ant-badge-status-green {
        background: #52c41a;
    }
    .ant-badge-status-blue {
        background: #1890ff;
    }
    .ant-badge-status-geekblue {
        background: #2f54eb;
    }
    .ant-badge-status-purple {
        background: #722ed1;
    }
    .ant-badge-status-text {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-ribbon {
        color: rgba(0, 0, 0, 0.85);
        color: #fff;
        background-color: #1890ff;
        border-radius: 2px;
    }
    .ant-ribbon-corner {
        color: #1890ff;
        border: 4px solid;
    }
    .ant-ribbon-corner::after {
        color: rgba(0, 0, 0, 0.25);
        border: inherit;
    }
    .ant-ribbon-color-pink {
        background-color: #eb2f96;
    }
    .ant-ribbon-color-pink .ant-ribbon-corner {
        color: #eb2f96;
    }
    .ant-ribbon-color-magenta {
        background-color: #eb2f96;
    }
    .ant-ribbon-color-magenta .ant-ribbon-corner {
        color: #eb2f96;
    }
    .ant-ribbon-color-red {
        background-color: #f5222d;
    }
    .ant-ribbon-color-red .ant-ribbon-corner {
        color: #f5222d;
    }
    .ant-ribbon-color-volcano {
        background-color: #fa541c;
    }
    .ant-ribbon-color-volcano .ant-ribbon-corner {
        color: #fa541c;
    }
    .ant-ribbon-color-orange {
        background-color: #fa8c16;
    }
    .ant-ribbon-color-orange .ant-ribbon-corner {
        color: #fa8c16;
    }
    .ant-ribbon-color-yellow {
        background-color: #fadb14;
    }
    .ant-ribbon-color-yellow .ant-ribbon-corner {
        color: #fadb14;
    }
    .ant-ribbon-color-gold {
        background-color: #faad14;
    }
    .ant-ribbon-color-gold .ant-ribbon-corner {
        color: #faad14;
    }
    .ant-ribbon-color-cyan {
        background-color: #13c2c2;
    }
    .ant-ribbon-color-cyan .ant-ribbon-corner {
        color: #13c2c2;
    }
    .ant-ribbon-color-lime {
        background-color: #a0d911;
    }
    .ant-ribbon-color-lime .ant-ribbon-corner {
        color: #a0d911;
    }
    .ant-ribbon-color-green {
        background-color: #52c41a;
    }
    .ant-ribbon-color-green .ant-ribbon-corner {
        color: #52c41a;
    }
    .ant-ribbon-color-blue {
        background-color: #1890ff;
    }
    .ant-ribbon-color-blue .ant-ribbon-corner {
        color: #1890ff;
    }
    .ant-ribbon-color-geekblue {
        background-color: #2f54eb;
    }
    .ant-ribbon-color-geekblue .ant-ribbon-corner {
        color: #2f54eb;
    }
    .ant-ribbon-color-purple {
        background-color: #722ed1;
    }
    .ant-ribbon-color-purple .ant-ribbon-corner {
        color: #722ed1;
    }
    .ant-ribbon.ant-ribbon-placement-end {
        border-bottom-right-radius: 0;
    }
    .ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
        border-color: currentColor transparent transparent currentColor;
    }
    .ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner::after {
        border-color: currentColor transparent transparent currentColor;
    }
    .ant-ribbon.ant-ribbon-placement-start {
        border-bottom-left-radius: 0;
    }
    .ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
        border-color: currentColor currentColor transparent transparent;
    }
    .ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner::after {
        border-color: currentColor currentColor transparent transparent;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-end {
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 0;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
        border-color: currentColor currentColor transparent transparent;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
        border-color: currentColor currentColor transparent transparent;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-start {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 2px;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
        border-color: currentColor transparent transparent currentColor;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
        border-color: currentColor transparent transparent currentColor;
    }
    .ant-breadcrumb {
        color: rgba(0, 0, 0, 0.85);
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-breadcrumb a {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-breadcrumb a:hover {
        color: #40a9ff;
    }
    .ant-breadcrumb > span:last-child {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-breadcrumb > span:last-child a {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-breadcrumb-separator {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-btn {
        background-image: none;
        border: 1px solid transparent;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
        border-radius: 5px;
        // color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-color: #d9d9d9;
    }
    .ant-btn:not([disabled]):active {
        box-shadow: none;
    }
    .ant-btn-lg {
        border-radius: 5px;
    }
    .ant-btn-sm {
        border-radius: 5px;
    }
    .ant-btn > a:only-child {
        color: currentColor;
    }
    .ant-btn > a:only-child::after {
        background: transparent;
    }
    .ant-btn:hover,
    .ant-btn:focus {
        color: #40a9ff;
        background: #fff;
        border-color: #40a9ff;
    }
    .ant-btn:hover > a:only-child,
    .ant-btn:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn:hover > a:only-child::after,
    .ant-btn:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn:active {
        color: #096dd9;
        background: #fff;
        border-color: #096dd9;
    }
    .ant-btn:active > a:only-child {
        color: currentColor;
    }
    .ant-btn:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
        color: rgba(255, 255, 255, 0.3);
        background: rgba(255, 255, 255, 0.08);
        border-color: rgba(255, 255, 255, 0.2);
        box-shadow: none;
    }
    .ant-btn[disabled] > a:only-child,
    .ant-btn[disabled]:hover > a:only-child,
    .ant-btn[disabled]:focus > a:only-child,
    .ant-btn[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn[disabled] > a:only-child::after,
    .ant-btn[disabled]:hover > a:only-child::after,
    .ant-btn[disabled]:focus > a:only-child::after,
    .ant-btn[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
        background: #fff;
    }
    .ant-btn-primary {
        color: #fff;
        background: #1890ff;
        border-color: #1890ff;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    }
    .ant-btn-primary > a:only-child {
        color: currentColor;
    }
    .ant-btn-primary > a:only-child::after {
        background: transparent;
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
        color: #fff;
        background: #40a9ff;
        border-color: #40a9ff;
    }
    .ant-btn-primary:hover > a:only-child,
    .ant-btn-primary:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-primary:hover > a:only-child::after,
    .ant-btn-primary:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-primary:active {
        color: #fff;
        background: #096dd9;
        border-color: #096dd9;
    }
    .ant-btn-primary:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-primary:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-btn-primary[disabled] > a:only-child,
    .ant-btn-primary[disabled]:hover > a:only-child,
    .ant-btn-primary[disabled]:focus > a:only-child,
    .ant-btn-primary[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-primary[disabled] > a:only-child::after,
    .ant-btn-primary[disabled]:hover > a:only-child::after,
    .ant-btn-primary[disabled]:focus > a:only-child::after,
    .ant-btn-primary[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
        border-right-color: #40a9ff;
        border-left-color: #40a9ff;
    }
    .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
        border-color: #d9d9d9;
    }
    .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
        border-right-color: #40a9ff;
    }
    .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
        border-right-color: #d9d9d9;
    }
    .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
    .ant-btn-group .ant-btn-primary + .ant-btn-primary {
        border-left-color: #40a9ff;
    }
    .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
    .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
        border-left-color: #d9d9d9;
    }
    .ant-btn-ghost {
        // color: rgba(255, 255, 255, 0.85);
        background: transparent;
        border-color: #d9d9d9;
    }
    .ant-btn-ghost > a:only-child {
        color: currentColor;
    }
    .ant-btn-ghost > a:only-child::after {
        background: transparent;
    }
    .ant-btn-ghost:hover,
    .ant-btn-ghost:focus {
        color: #40a9ff;
        background: transparent;
        border-color: #40a9ff;
    }
    .ant-btn-ghost:hover > a:only-child,
    .ant-btn-ghost:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-ghost:hover > a:only-child::after,
    .ant-btn-ghost:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-ghost:active {
        color: #096dd9;
        background: transparent;
        border-color: #096dd9;
    }
    .ant-btn-ghost:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-ghost:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-ghost[disabled],
    .ant-btn-ghost[disabled]:hover,
    .ant-btn-ghost[disabled]:focus,
    .ant-btn-ghost[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-btn-ghost[disabled] > a:only-child,
    .ant-btn-ghost[disabled]:hover > a:only-child,
    .ant-btn-ghost[disabled]:focus > a:only-child,
    .ant-btn-ghost[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-ghost[disabled] > a:only-child::after,
    .ant-btn-ghost[disabled]:hover > a:only-child::after,
    .ant-btn-ghost[disabled]:focus > a:only-child::after,
    .ant-btn-ghost[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dashed {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-color: #d9d9d9;
        border-style: dashed;
    }
    .ant-btn-dashed > a:only-child {
        color: currentColor;
    }
    .ant-btn-dashed > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dashed:hover,
    .ant-btn-dashed:focus {
        color: #40a9ff;
        background: #fff;
        border-color: #40a9ff;
    }
    .ant-btn-dashed:hover > a:only-child,
    .ant-btn-dashed:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-dashed:hover > a:only-child::after,
    .ant-btn-dashed:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dashed:active {
        color: #096dd9;
        background: #fff;
        border-color: #096dd9;
    }
    .ant-btn-dashed:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-dashed:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dashed[disabled],
    .ant-btn-dashed[disabled]:hover,
    .ant-btn-dashed[disabled]:focus,
    .ant-btn-dashed[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-btn-dashed[disabled] > a:only-child,
    .ant-btn-dashed[disabled]:hover > a:only-child,
    .ant-btn-dashed[disabled]:focus > a:only-child,
    .ant-btn-dashed[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-dashed[disabled] > a:only-child::after,
    .ant-btn-dashed[disabled]:hover > a:only-child::after,
    .ant-btn-dashed[disabled]:focus > a:only-child::after,
    .ant-btn-dashed[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-danger {
        color: #fff;
        background: #ff4d4f;
        border-color: #ff4d4f;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    }
    .ant-btn-danger > a:only-child {
        color: currentColor;
    }
    .ant-btn-danger > a:only-child::after {
        background: transparent;
    }
    .ant-btn-danger:hover,
    .ant-btn-danger:focus {
        color: #fff;
        background: #ff7875;
        border-color: #ff7875;
    }
    .ant-btn-danger:hover > a:only-child,
    .ant-btn-danger:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-danger:hover > a:only-child::after,
    .ant-btn-danger:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-danger:active {
        color: #fff;
        background: #d9363e;
        border-color: #d9363e;
    }
    .ant-btn-danger:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-danger:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-danger[disabled],
    .ant-btn-danger[disabled]:hover,
    .ant-btn-danger[disabled]:focus,
    .ant-btn-danger[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-btn-danger[disabled] > a:only-child,
    .ant-btn-danger[disabled]:hover > a:only-child,
    .ant-btn-danger[disabled]:focus > a:only-child,
    .ant-btn-danger[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-danger[disabled] > a:only-child::after,
    .ant-btn-danger[disabled]:hover > a:only-child::after,
    .ant-btn-danger[disabled]:focus > a:only-child::after,
    .ant-btn-danger[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-link {
        color: #1890ff;
        background: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-link > a:only-child {
        color: currentColor;
    }
    .ant-btn-link > a:only-child::after {
        background: transparent;
    }
    .ant-btn-link:hover,
    .ant-btn-link:focus {
        color: #40a9ff;
        background: transparent;
        border-color: #40a9ff;
    }
    .ant-btn-link:hover > a:only-child,
    .ant-btn-link:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-link:hover > a:only-child::after,
    .ant-btn-link:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-link:active {
        color: #096dd9;
        background: transparent;
        border-color: #096dd9;
    }
    .ant-btn-link:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-link:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-link[disabled],
    .ant-btn-link[disabled]:hover,
    .ant-btn-link[disabled]:focus,
    .ant-btn-link[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-btn-link[disabled] > a:only-child,
    .ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-link[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-link[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-link:hover {
        background: transparent;
    }
    .ant-btn-link:hover,
    .ant-btn-link:focus,
    .ant-btn-link:active {
        border-color: transparent;
    }
    .ant-btn-link[disabled],
    .ant-btn-link[disabled]:hover,
    .ant-btn-link[disabled]:focus,
    .ant-btn-link[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-link[disabled] > a:only-child,
    .ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-link[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-link[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-text {
        color: rgba(0, 0, 0, 0.85);
        background: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-text > a:only-child {
        color: currentColor;
    }
    .ant-btn-text > a:only-child::after {
        background: transparent;
    }
    .ant-btn-text:hover,
    .ant-btn-text:focus {
        color: #40a9ff;
        background: transparent;
        border-color: #40a9ff;
    }
    .ant-btn-text:hover > a:only-child,
    .ant-btn-text:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-text:hover > a:only-child::after,
    .ant-btn-text:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-text:active {
        color: #096dd9;
        background: transparent;
        border-color: #096dd9;
    }
    .ant-btn-text:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-text:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-text[disabled],
    .ant-btn-text[disabled]:hover,
    .ant-btn-text[disabled]:focus,
    .ant-btn-text[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-btn-text[disabled] > a:only-child,
    .ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-text[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-text[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-text:hover,
    .ant-btn-text:focus {
        color: rgba(0, 0, 0, 0.85);
        background: rgba(0, 0, 0, 0.018);
        border-color: transparent;
    }
    .ant-btn-text:active {
        color: rgba(0, 0, 0, 0.85);
        background: rgba(0, 0, 0, 0.028);
        border-color: transparent;
    }
    .ant-btn-text[disabled],
    .ant-btn-text[disabled]:hover,
    .ant-btn-text[disabled]:focus,
    .ant-btn-text[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-text[disabled] > a:only-child,
    .ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-text[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-text[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous {
        color: #ff4d4f;
        background: #fff;
        border-color: #ff4d4f;
    }
    .ant-btn-dangerous > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous:hover,
    .ant-btn-dangerous:focus {
        color: #ff7875;
        background: #fff;
        border-color: #ff7875;
    }
    .ant-btn-dangerous:hover > a:only-child,
    .ant-btn-dangerous:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous:hover > a:only-child::after,
    .ant-btn-dangerous:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous:active {
        color: #d9363e;
        background: #fff;
        border-color: #d9363e;
    }
    .ant-btn-dangerous:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous[disabled],
    .ant-btn-dangerous[disabled]:hover,
    .ant-btn-dangerous[disabled]:focus,
    .ant-btn-dangerous[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-btn-dangerous[disabled] > a:only-child,
    .ant-btn-dangerous[disabled]:hover > a:only-child,
    .ant-btn-dangerous[disabled]:focus > a:only-child,
    .ant-btn-dangerous[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous[disabled] > a:only-child::after,
    .ant-btn-dangerous[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary {
        color: #fff;
        background: #ff4d4f;
        border-color: #ff4d4f;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    }
    .ant-btn-dangerous.ant-btn-primary > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-primary > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary:hover,
    .ant-btn-dangerous.ant-btn-primary:focus {
        color: #fff;
        background: #ff7875;
        border-color: #ff7875;
    }
    .ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary:active {
        color: #fff;
        background: #d9363e;
        border-color: #d9363e;
    }
    .ant-btn-dangerous.ant-btn-primary:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary[disabled],
    .ant-btn-dangerous.ant-btn-primary[disabled]:hover,
    .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
    .ant-btn-dangerous.ant-btn-primary[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-link {
        color: #ff4d4f;
        background: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-link > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-link > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:hover,
    .ant-btn-dangerous.ant-btn-link:focus {
        color: #40a9ff;
        background: transparent;
        border-color: #40a9ff;
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:active {
        color: #096dd9;
        background: transparent;
        border-color: #096dd9;
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-link[disabled],
    .ant-btn-dangerous.ant-btn-link[disabled]:hover,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus,
    .ant-btn-dangerous.ant-btn-link[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:hover,
    .ant-btn-dangerous.ant-btn-link:focus {
        color: #ff7875;
        background: transparent;
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:active {
        color: #d9363e;
        background: transparent;
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-link[disabled],
    .ant-btn-dangerous.ant-btn-link[disabled]:hover,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus,
    .ant-btn-dangerous.ant-btn-link[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-text {
        color: #ff4d4f;
        background: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-text > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-text > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:hover,
    .ant-btn-dangerous.ant-btn-text:focus {
        color: #40a9ff;
        background: transparent;
        border-color: #40a9ff;
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:active {
        color: #096dd9;
        background: transparent;
        border-color: #096dd9;
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-text[disabled],
    .ant-btn-dangerous.ant-btn-text[disabled]:hover,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus,
    .ant-btn-dangerous.ant-btn-text[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:hover,
    .ant-btn-dangerous.ant-btn-text:focus {
        color: #ff7875;
        background: rgba(0, 0, 0, 0.018);
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:active {
        color: #d9363e;
        background: rgba(0, 0, 0, 0.028);
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-dangerous.ant-btn-text[disabled],
    .ant-btn-dangerous.ant-btn-text[disabled]:hover,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus,
    .ant-btn-dangerous.ant-btn-text[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-icon-only {
        border-radius: 2px;
    }
    .ant-btn-icon-only.ant-btn-lg {
        border-radius: 2px;
    }
    .ant-btn-icon-only.ant-btn-sm {
        border-radius: 2px;
    }
    .ant-btn-round {
        border-radius: 32px;
    }
    .ant-btn-round.ant-btn-lg {
        border-radius: 40px;
    }
    .ant-btn-round.ant-btn-sm {
        border-radius: 24px;
    }
    .ant-btn-circle,
    .ant-btn-circle-outline {
        border-radius: 50%;
    }
    .ant-btn-circle.ant-btn-lg,
    .ant-btn-circle-outline.ant-btn-lg {
        border-radius: 50%;
    }
    .ant-btn-circle.ant-btn-sm,
    .ant-btn-circle-outline.ant-btn-sm {
        border-radius: 50%;
    }
    .ant-btn::before {
        background: #fff;
        border-radius: inherit;
    }
    .ant-btn-group-lg > .ant-btn,
    .ant-btn-group-lg > span > .ant-btn {
        border-radius: 0;
    }
    .ant-btn-group-sm > .ant-btn,
    .ant-btn-group-sm > span > .ant-btn {
        border-radius: 0;
    }
    .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
        border-left-color: transparent;
    }
    .ant-btn-group .ant-btn {
        border-radius: 0;
    }
    .ant-btn-group > .ant-btn:only-child {
        border-radius: 2px;
    }
    .ant-btn-group > span:only-child > .ant-btn {
        border-radius: 2px;
    }
    .ant-btn-group > .ant-btn:first-child:not(:last-child),
    .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }
    .ant-btn-group > .ant-btn:last-child:not(:first-child),
    .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
    .ant-btn-group-sm > .ant-btn:only-child {
        border-radius: 2px;
    }
    .ant-btn-group-sm > span:only-child > .ant-btn {
        border-radius: 2px;
    }
    .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
    .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }
    .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
    .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
    .ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
        border-radius: 0;
    }
    .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
    .ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
        border-top-left-radius: 0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 0;
    }
    .ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
    .ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
        border-top-left-radius: 2px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 2px;
    }
    .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
    .ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
        border-top-left-radius: 0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 0;
    }
    .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
    .ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
        border-top-left-radius: 2px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 2px;
    }
    .ant-btn-background-ghost {
        color: #fff;
        background: transparent !important;
        // border-color: #fff;
    }
    .ant-btn-background-ghost.ant-btn-primary {
        color: #1890ff;
        background: transparent;
        border-color: #1890ff;
    }
    .ant-btn-background-ghost.ant-btn-primary > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-primary:hover,
    .ant-btn-background-ghost.ant-btn-primary:focus {
        color: #40a9ff;
        background: transparent;
        border-color: #40a9ff;
    }
    .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-primary:active {
        color: #096dd9;
        background: transparent;
        border-color: #096dd9;
    }
    .ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-primary[disabled],
    .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger {
        color: #ff4d4f;
        background: transparent;
        border-color: #ff4d4f;
    }
    .ant-btn-background-ghost.ant-btn-danger > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger:hover,
    .ant-btn-background-ghost.ant-btn-danger:focus {
        color: #ff7875;
        background: transparent;
        border-color: #ff7875;
    }
    .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger:active {
        color: #d9363e;
        background: transparent;
        border-color: #d9363e;
    }
    .ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger[disabled],
    .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous {
        color: #ff4d4f;
        background: transparent;
        border-color: #ff4d4f;
    }
    .ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:hover,
    .ant-btn-background-ghost.ant-btn-dangerous:focus {
        color: #ff7875;
        background: transparent;
        border-color: #ff7875;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:active {
        color: #d9363e;
        background: transparent;
        border-color: #d9363e;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous[disabled],
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
        color: #ff4d4f;
        background: transparent;
        border-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
        color: #ff7875;
        background: transparent;
        border-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
        color: #d9363e;
        background: transparent;
        border-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
        color: currentColor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
        background: transparent;
    }
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
        border-right-color: #40a9ff;
        border-left-color: #d9d9d9;
    }
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
        border-right-color: #d9d9d9;
        border-left-color: #40a9ff;
    }
    .ant-picker-calendar {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
    }
    .ant-picker-calendar .ant-picker-panel {
        background: #fff;
        border: 0;
        border-top: 1px solid #f0f0f0;
        border-radius: 0;
    }
    .ant-picker-calendar-mini {
        border-radius: 2px;
    }
    .ant-picker-calendar-mini .ant-picker-panel {
        border-radius: 0 0 2px 2px;
    }
    .ant-picker-calendar-full .ant-picker-panel {
        background: #fff;
        border: 0;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
        background: #f5f5f5;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
        background: #e6f7ff;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
        color: #1890ff;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
        border: 0;
        border-top: 2px solid #f0f0f0;
        border-radius: 0;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
        border-color: #1890ff;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-card {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08), 0 8px 20px 0 rgba(218, 224, 235, 0.6);
    }
    .ant-card-hoverable:hover {
        border-color: transparent;
        box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    }
    .ant-card-bordered {
        border: 1px solid #f0f0f0;
    }
    .ant-card-head {
        color: rgba(0, 0, 0, 0.85);
        background: linear-gradient(180deg, #fff, #f5f7f9);
        border-bottom: 1px solid #f0f0f0;
        border-radius: 2px 2px 0 0;
    }
    .ant-card-head .ant-tabs {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-card-head .ant-tabs-bar {
        border-bottom: 1px solid #f0f0f0;
    }

    .ant-tabs-bar {
        border-bottom: 1px solid #f0f0f0;
    }

    .ant-card-extra {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-card-grid {
        border: 0;
        border-radius: 0;
        box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
    }
    .ant-card-grid-hoverable:hover {
        box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    }
    .ant-card-cover img {
        border-radius: 2px 2px 0 0;
    }
    .ant-card-actions {
        background: #fff;
        border-top: 1px solid #f0f0f0;
    }
    .ant-card-actions > li {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-card-actions > li > span:hover {
        color: #1890ff;
    }
    .ant-card-actions > li > span a:not(.ant-btn),
    .ant-card-actions > li > span > .anticon {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-card-actions > li > span a:not(.ant-btn):hover,
    .ant-card-actions > li > span > .anticon:hover {
        color: #1890ff;
    }
    .ant-card-actions > li:not(:last-child) {
        border-right: 1px solid #f0f0f0;
    }
    .ant-card-type-inner .ant-card-head {
        background: #fafafa;
    }
    .ant-card-meta-title {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-card-meta-description {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-card-loading-block {
        background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
        background-size: 600% 600%;
        border-radius: 2px;
    }
    .ant-carousel {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-carousel .slick-slider {
        -webkit-tap-highlight-color: transparent;
    }
    .ant-carousel .slick-prev,
    .ant-carousel .slick-next {
        color: transparent;
        background: transparent;
        border: 0;
    }
    .ant-carousel .slick-prev:hover,
    .ant-carousel .slick-next:hover,
    .ant-carousel .slick-prev:focus,
    .ant-carousel .slick-next:focus {
        color: transparent;
        background: transparent;
    }
    .ant-carousel .slick-dots li button {
        color: transparent;
        background: #fff;
        border: 0;
        border-radius: 1px;
    }
    .ant-carousel .slick-dots li.slick-active button {
        background: #fff;
    }
    .ant-cascader {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-cascader-input.ant-input {
        background-color: transparent !important;
    }
    .ant-cascader-picker {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fff;
        border-radius: 2px;
    }
    .ant-cascader-picker-with-value .ant-cascader-picker-label {
        color: transparent;
    }
    .ant-cascader-picker-disabled {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
    }
    .ant-cascader-picker:focus .ant-cascader-input {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-input-rtl .ant-cascader-picker:focus .ant-cascader-input {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-cascader-picker-borderless .ant-cascader-input {
        border-color: transparent !important;
        box-shadow: none !important;
    }
    .ant-cascader-picker-show-search.ant-cascader-picker-focused {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-cascader-picker-clear {
        color: rgba(0, 0, 0, 0.25);
        background: #fff;
    }
    .ant-cascader-picker-clear:hover {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-cascader-picker-arrow {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
        border-color: #40a9ff;
        border-right-width: 1px !important;
    }
    .ant-input-rtl
        .ant-cascader-picker-label:hover
        + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-cascader-menus {
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .ant-cascader-menu {
        border-right: 1px solid #f0f0f0;
    }
    .ant-cascader-menu:first-child {
        border-radius: 2px 0 0 2px;
    }
    .ant-cascader-menu:last-child {
        border-right-color: transparent;
        border-radius: 0 2px 2px 0;
    }
    .ant-cascader-menu:only-child {
        border-radius: 2px;
    }
    .ant-cascader-menu-item:hover {
        background: #f5f5f5;
    }
    .ant-cascader-menu-item-disabled {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-cascader-menu-item-disabled:hover {
        background: transparent;
    }
    .ant-cascader-menu-empty .ant-cascader-menu-item {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
        background-color: #e6f7ff;
    }
    .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
    .ant-cascader-menu-item-loading-icon {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
    .ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-cascader-menu-item .ant-cascader-menu-item-keyword {
        color: #ff4d4f;
    }
    .ant-cascader-menu-rtl .ant-cascader-menu {
        border-right: none;
        border-left: 1px solid #f0f0f0;
    }
    .ant-cascader-menu-rtl .ant-cascader-menu:first-child {
        border-radius: 0 2px 2px 0;
    }
    .ant-cascader-menu-rtl .ant-cascader-menu:last-child {
        border-left-color: transparent;
        border-radius: 2px 0 0 2px;
    }
    .ant-cascader-menu-rtl .ant-cascader-menu:only-child {
        border-radius: 2px;
    }
    .ant-checkbox {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #1890ff;
    }
    .ant-checkbox-checked::after {
        border: 1px solid #1890ff;
        border-radius: 2px;
    }
    .ant-checkbox-inner {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        border-collapse: separate;
    }
    .ant-checkbox-inner::after {
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #1890ff;
        border-color: #1890ff;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: rgba(0, 0, 0, 0.25);
    }
    .ant-checkbox-disabled .ant-checkbox-inner {
        background-color: #f5f5f5;
        border-color: #d9d9d9 !important;
    }
    .ant-checkbox-disabled .ant-checkbox-inner::after {
        border-color: #f5f5f5;
        border-collapse: separate;
    }
    .ant-checkbox-disabled + span {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-checkbox-wrapper {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-checkbox-group {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner {
        background-color: #fff;
        border-color: #d9d9d9;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: #1890ff;
        border: 0;
    }
    .ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: rgba(0, 0, 0, 0.25);
    }
    .ant-collapse {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fafafa;
        border: 1px solid #d9d9d9;
        border-bottom: 0;
        border-radius: 2px;
    }
    .ant-collapse > .ant-collapse-item {
        border-bottom: 1px solid #d9d9d9;
    }
    .ant-collapse > .ant-collapse-item:last-child,
    .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
        border-radius: 0 0 2px 2px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        color: inherit;
    }
    .ant-collapse-content {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fff;
        border-top: 1px solid #d9d9d9;
    }
    .ant-collapse-item:last-child > .ant-collapse-content {
        border-radius: 0 0 2px 2px;
    }
    .ant-collapse-borderless {
        background-color: #fafafa;
        border: 0;
    }
    .ant-collapse-borderless > .ant-collapse-item {
        border-bottom: 1px solid #d9d9d9;
    }
    .ant-collapse-borderless > .ant-collapse-item:last-child,
    .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
        border-radius: 0;
    }
    .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
        background-color: transparent;
        border-top: 0;
    }
    .ant-collapse-ghost {
        background-color: transparent;
        border: 0;
    }
    .ant-collapse-ghost > .ant-collapse-item {
        border-bottom: 0;
    }
    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
        background-color: transparent;
        border-top: 0;
    }
    .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
    .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-comment {
        background-color: inherit;
    }
    .ant-comment-avatar img {
        border-radius: 50%;
    }
    .ant-comment-content-author-name {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-comment-content-author-name > * {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-comment-content-author-name > *:hover {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-comment-content-author-time {
        color: #ccc;
    }
    .ant-comment-actions > li {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-comment-actions > li > span {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-comment-actions > li > span:hover {
        color: #595959;
    }
    .ant-picker-panel {
        background: #fff;
        border: 1px solid #f0f0f0;
        border-radius: 2px;
    }
    .ant-picker-panel-focused {
        border-color: #1890ff;
    }
    .ant-picker-header {
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 1px solid #f0f0f0;
    }
    .ant-picker-header button {
        color: rgba(0, 0, 0, 0.25);
        background: transparent;
        border: 0;
    }
    .ant-picker-header > button:hover {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-picker-header-view button {
        color: inherit;
    }
    .ant-picker-header-view button:hover {
        color: #1890ff;
    }
    .ant-picker-prev-icon::before,
    .ant-picker-next-icon::before,
    .ant-picker-super-prev-icon::before,
    .ant-picker-super-next-icon::before {
        border: 0 solid currentColor;
        border-width: 1.5px 0 0 1.5px;
    }
    .ant-picker-super-prev-icon::after,
    .ant-picker-super-next-icon::after {
        border: 0 solid currentColor;
        border-width: 1.5px 0 0 1.5px;
    }
    .ant-picker-content {
        border-collapse: collapse;
    }
    .ant-picker-content th {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-picker-cell {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-picker-cell-in-view {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-picker-cell .ant-picker-cell-inner {
        border-radius: 2px;
    }
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
        .ant-picker-cell-inner {
        background: #f5f5f5;
    }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border: 1px solid #1890ff;
        border-radius: 2px;
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background: #e6f7ff;
    }
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        color: #fff;
        background: #1890ff;
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
        background: #e6f7ff;
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
        border-top: 1px dashed #7ec1ff;
        border-bottom: 1px dashed #7ec1ff;
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
    .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
    .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
        background: #cbe6ff;
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
        border-radius: 2px 0 0 2px;
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
        border-radius: 0 2px 2px 0;
    }
    .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
    .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
        background: #cbe6ff;
    }
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
        border-left: 1px dashed #7ec1ff;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
        border-right: 1px dashed #7ec1ff;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
    .ant-picker-cell-disabled .ant-picker-cell-inner {
        color: rgba(0, 0, 0, 0.25);
        background: transparent;
    }
    .ant-picker-cell-disabled::before {
        background: #f5f5f5;
    }
    .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: rgba(0, 0, 0, 0.25);
    }
    .ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
    .ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
    .ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
    .ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
        background: #f5f5f5;
    }
    .ant-picker-footer {
        border-bottom: 1px solid transparent;
    }
    .ant-picker-panel .ant-picker-footer {
        border-top: 1px solid #f0f0f0;
    }
    .ant-picker-footer-extra:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
    }
    .ant-picker-today-btn {
        color: #1890ff;
    }
    .ant-picker-today-btn:hover {
        color: #40a9ff;
    }
    .ant-picker-today-btn:active {
        color: #096dd9;
    }
    .ant-picker-today-btn.ant-picker-today-btn-disabled {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
        border-left: 1px dashed #7ec1ff;
        border-radius: 2px 0 0 2px;
    }
    .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
        border-right: 1px dashed #7ec1ff;
        border-radius: 0 2px 2px 0;
    }
    .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
        border-right: 1px dashed #7ec1ff;
        border-radius: 0 2px 2px 0;
    }
    .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
        border-left: 1px dashed #7ec1ff;
        border-radius: 2px 0 0 2px;
    }
    .ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
    .ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
        background: transparent !important;
    }
    .ant-picker-week-panel-row:hover td {
        background: #f5f5f5;
    }
    .ant-picker-week-panel-row-selected td,
    .ant-picker-week-panel-row-selected:hover td {
        background: #1890ff;
    }
    .ant-picker-week-panel-row-selected td.ant-picker-cell-week,
    .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
        color: rgba(255, 255, 255, 0.5);
    }
    .ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
    .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: #fff;
    }
    .ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
    .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
        color: #fff;
    }
    .ant-picker-datetime-panel .ant-picker-time-panel {
        border-left: 1px solid #f0f0f0;
    }
    .ant-picker-time-panel-column:not(:first-child) {
        border-left: 1px solid #f0f0f0;
    }
    .ant-picker-time-panel-column-active {
        background: rgba(230, 247, 255, 0.2);
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
        color: rgba(0, 0, 0, 0.85);
        border-radius: 0;
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
        background: #f5f5f5;
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
        background: #e6f7ff;
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
        color: rgba(0, 0, 0, 0.25);
        background: transparent;
    }
    .ant-picker {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-picker:hover,
    .ant-picker-focused {
        border-color: #40a9ff;
        border-right-width: 1px !important;
    }
    .ant-input-rtl .ant-picker:hover,
    .ant-input-rtl .ant-picker-focused {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-picker-focused {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-input-rtl .ant-picker-focused {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-picker.ant-picker-disabled {
        background: #f5f5f5;
        border-color: #d9d9d9;
    }
    .ant-picker.ant-picker-disabled .ant-picker-suffix {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-picker.ant-picker-borderless {
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }
    .ant-picker-input > input {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        background: transparent;
        border: 0;
    }
    .ant-picker-input > input::placeholder {
        color: #bfbfbf;
    }
    .ant-picker-input > input:hover {
        border-color: #40a9ff;
        border-right-width: 1px !important;
    }
    .ant-input-rtl .ant-picker-input > input:hover {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-picker-input > input:focus,
    .ant-picker-input > input-focused {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-input-rtl .ant-picker-input > input:focus,
    .ant-input-rtl .ant-picker-input > input-focused {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-picker-input > input-disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
    }
    .ant-picker-input > input-disabled:hover {
        border-color: #d9d9d9;
        border-right-width: 1px !important;
    }
    .ant-picker-input > input[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
    }
    .ant-picker-input > input[disabled]:hover {
        border-color: #d9d9d9;
        border-right-width: 1px !important;
    }
    .ant-picker-input > input-borderless,
    .ant-picker-input > input-borderless:hover,
    .ant-picker-input > input-borderless:focus,
    .ant-picker-input > input-borderless-focused,
    .ant-picker-input > input-borderless-disabled,
    .ant-picker-input > input-borderless[disabled] {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
    .ant-picker-input > input:focus {
        box-shadow: none;
    }
    .ant-picker-input > input[disabled] {
        background: transparent;
    }
    .ant-picker-input-placeholder > input {
        color: #bfbfbf;
    }
    .ant-picker-suffix {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-picker-clear {
        color: rgba(0, 0, 0, 0.25);
        background: #fff;
    }
    .ant-picker-clear:hover {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-picker-separator {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-picker-focused .ant-picker-separator {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-picker-range .ant-picker-active-bar {
        background: #1890ff;
    }
    .ant-picker-dropdown {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
        color: #1890ff;
        background: #e6f7ff;
        border-color: #91d5ff;
    }
    .ant-picker-range-arrow {
        box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
    }
    .ant-picker-range-arrow::after {
        border: 5px solid #f0f0f0;
        border-color: #fff #fff transparent transparent;
    }
    .ant-picker-panel-container {
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .ant-picker-panel-container .ant-picker-panel {
        background: transparent;
        border-width: 0 0 1px 0;
        border-radius: 0;
    }
    .ant-picker-panel-container .ant-picker-panel-focused {
        border-color: #f0f0f0;
    }
    .ant-picker-cell .ant-picker-cell-inner {
        border-radius: 2px;
    }
    .ant-picker-panel-rtl
        .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
        .ant-picker-cell-inner {
        border-radius: 0 2px 2px 0;
    }
    .ant-picker-panel-rtl
        .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
        .ant-picker-cell-inner {
        border-radius: 2px 0 0 2px;
    }
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
        border-right: 1px dashed #7ec1ff;
        border-left: none;
        border-top-left-radius: 0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 0;
    }
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
        border-right: none;
        border-left: 1px dashed #7ec1ff;
        border-top-left-radius: 2px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 2px;
    }
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
        border-right: 1px dashed #7ec1ff;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after {
        border-left: 1px dashed #7ec1ff;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }
    .ant-descriptions-title {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-descriptions-extra {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-descriptions-view {
        border-radius: 2px;
    }
    .ant-descriptions-row:last-child {
        border-bottom: none;
    }
    .ant-descriptions-item-label {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-descriptions-item-content {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-descriptions-bordered .ant-descriptions-view {
        border: 1px solid #f0f0f0;
    }
    .ant-descriptions-bordered .ant-descriptions-item-label,
    .ant-descriptions-bordered .ant-descriptions-item-content {
        border-right: 1px solid #f0f0f0;
    }
    .ant-descriptions-bordered .ant-descriptions-item-label:last-child,
    .ant-descriptions-bordered .ant-descriptions-item-content:last-child {
        border-right: none;
    }
    .ant-descriptions-bordered .ant-descriptions-item-label {
        background-color: #fafafa;
    }
    .ant-descriptions-bordered .ant-descriptions-row {
        border-bottom: 1px solid #f0f0f0;
    }
    .ant-descriptions-bordered .ant-descriptions-row:last-child {
        border-bottom: none;
    }
    .ant-divider {
        color: rgba(0, 0, 0, 0.85);
        border-top: 1px solid rgba(0, 0, 0, 0.06);
    }
    .ant-divider-vertical {
        border-top: 0;
        border-left: 1px solid rgba(0, 0, 0, 0.06);
    }
    .ant-divider-horizontal.ant-divider-with-text {
        color: rgba(0, 0, 0, 0.85);
        border-color: rgba(0, 0, 0, 0.06);
        border-top: 0;
    }
    .ant-divider-horizontal.ant-divider-with-text::before,
    .ant-divider-horizontal.ant-divider-with-text::after {
        border-top: 1px solid transparent;
        border-top-color: inherit;
        border-bottom: 0;
    }
    .ant-divider-dashed {
        background: none;
        border-color: rgba(0, 0, 0, 0.06);
        border-style: dashed;
        border-width: 1px 0 0;
    }
    .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
        border-top: 0;
    }
    .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
    .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
        border-style: dashed none none;
    }
    .ant-divider-vertical.ant-divider-dashed {
        border-width: 0 0 0 1px;
    }
    .ant-divider-plain.ant-divider-with-text {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
    }
    .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
    }
    .ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
    }
    .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
    }
    .ant-drawer-title {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-drawer-content {
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
    }
    .ant-drawer-close {
        color: rgba(0, 0, 0, 0.45);
        background: transparent;
        border: 0;
    }
    .ant-drawer-close:focus,
    .ant-drawer-close:hover {
        color: rgba(0, 0, 0, 0.75);
    }
    .ant-drawer-header {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-bottom: 1px solid #f0f0f0;
        border-radius: 2px 2px 0 0;
    }
    .ant-drawer-header-no-title {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
    }
    .ant-drawer-footer {
        border-top: 1px solid #f0f0f0;
    }
    .ant-drawer-mask {
        background-color: rgba(0, 0, 0, 0.45);
    }
    .ant-drawer-open-content {
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .ant-drawer .ant-picker-clear {
        background: #fff;
    }
    .ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
        color: #ff4d4f;
    }
    .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
        color: #fff;
        background-color: #ff4d4f;
    }
    .ant-dropdown {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-dropdown-arrow {
        background: transparent;
        border-style: solid;
        border-width: 4.24264069px;
    }
    .ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
    .ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
    .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
        border-top-color: transparent;
        border-right-color: #fff;
        border-bottom-color: #fff;
        border-left-color: transparent;
        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    }
    .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
    .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
    .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
        border-top-color: #fff;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: #fff;
        box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
    }
    .ant-dropdown-menu {
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 2px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .ant-dropdown-menu-item-group-title {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-dropdown-menu-submenu-popup {
        background: transparent;
        box-shadow: none;
    }
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-dropdown-menu-item > a,
    .ant-dropdown-menu-submenu-title > a {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-dropdown-menu-item > a:hover,
    .ant-dropdown-menu-submenu-title > a:hover {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-dropdown-menu-item > .anticon + span > a,
    .ant-dropdown-menu-submenu-title > .anticon + span > a {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-dropdown-menu-item > .anticon + span > a:hover,
    .ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-dropdown-menu-item-selected,
    .ant-dropdown-menu-submenu-title-selected,
    .ant-dropdown-menu-item-selected > a,
    .ant-dropdown-menu-submenu-title-selected > a {
        color: #1890ff;
        background-color: #e6f7ff;
    }
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
        background-color: #f5f5f5;
    }
    .ant-dropdown-menu-item-disabled,
    .ant-dropdown-menu-submenu-title-disabled {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-dropdown-menu-item-disabled:hover,
    .ant-dropdown-menu-submenu-title-disabled:hover {
        color: rgba(0, 0, 0, 0.25);
        background-color: #fff;
    }
    .ant-dropdown-menu-item-divider,
    .ant-dropdown-menu-submenu-title-divider {
        background-color: #f0f0f0;
    }
    .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
    .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
    .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
        color: rgba(0, 0, 0, 0.25);
        background-color: #fff;
    }
    .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
        color: #1890ff;
    }
    .ant-dropdown-menu-dark,
    .ant-dropdown-menu-dark .ant-dropdown-menu {
        background: #001529;
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item,
    .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
        color: rgba(255, 255, 255, 0.65);
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
    .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
        color: rgba(255, 255, 255, 0.65);
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
        color: #fff;
        background: transparent;
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
        color: #fff;
        background: #1890ff;
    }
    .ant-empty-normal {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-empty-small {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-form-item .ant-upload {
        background: transparent;
    }
    .ant-form-item .ant-upload.ant-upload-drag {
        background: #fafafa;
    }
    .ant-form-item-explain.ant-form-item-explain-error {
        color: #ff4d4f;
    }
    .ant-form-item-explain.ant-form-item-explain-warning {
        color: #faad14;
    }
    .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: #52c41a;
    }
    .ant-form-item-has-warning .ant-form-item-split {
        color: #faad14;
    }
    .ant-form-item-has-warning .ant-input,
    .ant-form-item-has-warning .ant-input-affix-wrapper,
    .ant-form-item-has-warning .ant-input:hover,
    .ant-form-item-has-warning .ant-input-affix-wrapper:hover {
        border-color: #faad14;
    }
    .ant-form-item-has-warning .ant-input:focus,
    .ant-form-item-has-warning .ant-input-affix-wrapper:focus,
    .ant-form-item-has-warning .ant-input-focused,
    .ant-form-item-has-warning .ant-input-affix-wrapper-focused {
        border-color: #ffc53d;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    }
    .ant-form-item-has-warning .ant-input:not(.ant-form-item-has-warning .ant-input-disabled) {
        background-color: #fff;
    }
    .ant-form-item-has-warning .ant-input-affix-wrapper:not(.ant-form-item-has-warning .ant-input-affix-wrapper-disabled) {
        background-color: #fff;
    }
    .ant-form-item-has-warning .ant-input-affix-wrapper input:focus {
        box-shadow: none !important;
    }
    .ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
        border-color: #ffc53d;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    }
    .ant-form-item-has-warning .ant-input-prefix {
        color: #faad14;
    }
    .ant-form-item-has-warning .ant-input-group-addon {
        color: #faad14;
        border-color: #faad14;
    }
    .ant-form-item-has-warning .has-feedback {
        color: #faad14;
    }
    .ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: #faad14;
    }
    .ant-form-item-has-warning .ant-select:not(.ant-select-borderless) .ant-select-selector {
        border-color: #faad14 !important;
    }
    .ant-form-item-has-warning .ant-select:not(.ant-select-borderless).ant-select-open .ant-select-selector,
    .ant-form-item-has-warning .ant-select:not(.ant-select-borderless).ant-select-focused .ant-select-selector {
        border-color: #ffc53d;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    }
    .ant-form-item-has-warning .ant-input-number,
    .ant-form-item-has-warning .ant-picker {
        border-color: #faad14;
    }
    .ant-form-item-has-warning .ant-input-number-focused,
    .ant-form-item-has-warning .ant-picker-focused,
    .ant-form-item-has-warning .ant-input-number:focus,
    .ant-form-item-has-warning .ant-picker:focus {
        border-color: #ffc53d;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    }
    .ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
    .ant-form-item-has-warning .ant-picker:not([disabled]):hover {
        border-color: #faad14;
    }
    .ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
        border-color: #ffc53d;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    }
    .ant-form-item-has-error .ant-form-item-split {
        color: #ff4d4f;
    }
    .ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-input-affix-wrapper,
    .ant-form-item-has-error .ant-input:hover,
    .ant-form-item-has-error .ant-input-affix-wrapper:hover {
        border-color: #ff4d4f;
    }
    .ant-form-item-has-error .ant-input:focus,
    .ant-form-item-has-error .ant-input-affix-wrapper:focus,
    .ant-form-item-has-error .ant-input-focused,
    .ant-form-item-has-error .ant-input-affix-wrapper-focused {
        border-color: #ff7875;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    }
    .ant-form-item-has-error .ant-input:not(.ant-form-item-has-error .ant-input-disabled) {
        background-color: #fff;
    }
    .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-form-item-has-error .ant-input-affix-wrapper-disabled) {
        background-color: #fff;
    }
    .ant-form-item-has-error .ant-input-affix-wrapper input:focus {
        box-shadow: none !important;
    }
    .ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
        border-color: #ff7875;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    }
    .ant-form-item-has-error .ant-input-prefix {
        color: #ff4d4f;
    }
    .ant-form-item-has-error .ant-input-group-addon {
        color: #ff4d4f;
        border-color: #ff4d4f;
    }
    .ant-form-item-has-error .has-feedback {
        color: #ff4d4f;
    }
    .ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: #ff4d4f;
    }
    .ant-form-item-has-error .ant-select:not(.ant-select-borderless) .ant-select-selector {
        border-color: #ff4d4f !important;
    }
    .ant-form-item-has-error .ant-select:not(.ant-select-borderless).ant-select-open .ant-select-selector,
    .ant-form-item-has-error .ant-select:not(.ant-select-borderless).ant-select-focused .ant-select-selector {
        border-color: #ff7875;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    }
    .ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border: 0;
    }
    .ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
        border-color: #ff4d4f;
    }
    .ant-form-item-has-error .ant-input-number,
    .ant-form-item-has-error .ant-picker {
        border-color: #ff4d4f;
    }
    .ant-form-item-has-error .ant-input-number-focused,
    .ant-form-item-has-error .ant-picker-focused,
    .ant-form-item-has-error .ant-input-number:focus,
    .ant-form-item-has-error .ant-picker:focus {
        border-color: #ff7875;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    }
    .ant-form-item-has-error .ant-input-number:not([disabled]):hover,
    .ant-form-item-has-error .ant-picker:not([disabled]):hover {
        border-color: #ff4d4f;
    }
    .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
    .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
        border-color: #ff4d4f;
    }
    .ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
    .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
        border-color: #ff7875;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    }
    .ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
        border-color: #ff7875;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    }
    .ant-form-item-has-error .ant-transfer-list {
        border-color: #ff4d4f;
    }
    .ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
        border-color: #d9d9d9;
    }
    .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
        border-color: #40a9ff;
        border-right-width: 1px !important;
    }
    .ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-form-item-has-error .ant-radio-button-wrapper {
        border-color: #ff4d4f !important;
    }
    .ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: #ff4d4f;
    }
    .ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: #1890ff;
    }
    .ant-form {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-form legend {
        color: rgba(0, 0, 0, 0.45);
        border: 0;
        border-bottom: 1px solid #d9d9d9;
    }
    .ant-form output {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-form-item {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-form-item-label > label {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        color: #ff4d4f;
    }
    .ant-form-item-label > label .ant-form-item-optional {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-form-item-label > label .ant-form-item-tooltip {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-form-item-explain,
    .ant-form-item-extra {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-image-img-placeholder {
        background-color: #f5f5f5;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 30%;
    }
    .ant-image-preview-operations {
        color: rgba(0, 0, 0, 0.85);
        color: rgba(255, 255, 255, 0.85);
        background: rgba(0, 0, 0, 0.1);
    }
    .ant-image-preview-operations-operation-disabled {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-input-affix-wrapper {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-input-affix-wrapper::placeholder {
        color: #bfbfbf;
    }
    .ant-input-affix-wrapper:hover {
        border-color: #40a9ff;
        border-right-width: 1px !important;
    }
    .ant-input-rtl .ant-input-affix-wrapper:hover {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-input-rtl .ant-input-affix-wrapper:focus,
    .ant-input-rtl .ant-input-affix-wrapper-focused {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-input-affix-wrapper-disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
    }
    .ant-input-affix-wrapper-disabled:hover {
        border-color: #d9d9d9;
        border-right-width: 1px !important;
    }
    .ant-input-affix-wrapper[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
    }
    .ant-input-affix-wrapper[disabled]:hover {
        border-color: #d9d9d9;
        border-right-width: 1px !important;
    }
    .ant-input-affix-wrapper-borderless,
    .ant-input-affix-wrapper-borderless:hover,
    .ant-input-affix-wrapper-borderless:focus,
    .ant-input-affix-wrapper-borderless-focused,
    .ant-input-affix-wrapper-borderless-disabled,
    .ant-input-affix-wrapper-borderless[disabled] {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
    .ant-input-affix-wrapper-disabled .ant-input[disabled] {
        background: transparent;
    }
    .ant-input-affix-wrapper > input.ant-input {
        border: none;
    }
    .ant-input-affix-wrapper > input.ant-input:focus {
        box-shadow: none;
    }
    .ant-input-clear-icon {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-input-clear-icon:hover {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-input-clear-icon:active {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-input-affix-wrapper-textarea-with-clear-btn {
        border: 0 !important;
    }
    .ant-input-textarea-clear-icon {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-input-textarea-clear-icon:hover {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-input-textarea-clear-icon:active {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-input {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-input::placeholder {
        color: #bfbfbf;
    }
    .ant-input:hover {
        border-color: #40a9ff;
        border-right-width: 1px !important;
    }
    .ant-input-rtl .ant-input:hover {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-input:focus,
    .ant-input-focused {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-input-rtl .ant-input:focus,
    .ant-input-rtl .ant-input-focused {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-input-disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
    }
    .ant-input-disabled:hover {
        border-color: #d9d9d9;
        border-right-width: 1px !important;
    }
    .ant-input[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
    }
    .ant-input[disabled]:hover {
        border-color: #d9d9d9;
        border-right-width: 1px !important;
    }
    .ant-input-borderless,
    .ant-input-borderless:hover,
    .ant-input-borderless:focus,
    .ant-input-borderless-focused,
    .ant-input-borderless-disabled,
    .ant-input-borderless[disabled] {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
    .ant-input-group {
        color: rgba(0, 0, 0, 0.85);
        border-collapse: separate;
        border-spacing: 0;
    }
    .ant-input-group-addon:not(:first-child):not(:last-child),
    .ant-input-group-wrap:not(:first-child):not(:last-child),
    .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
        border-radius: 0;
    }
    .ant-input-group .ant-input:focus {
        border-right-width: 1px;
    }
    .ant-input-group .ant-input:hover {
        border-right-width: 1px;
    }
    .ant-input-group-addon {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fafafa;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        background-color: inherit;
        border: 1px solid transparent;
        box-shadow: none;
    }
    .ant-input-group-addon .ant-select-open .ant-select-selector,
    .ant-input-group-addon .ant-select-focused .ant-select-selector {
        color: #1890ff;
    }
    .ant-input-group > .ant-input:first-child,
    .ant-input-group-addon:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
    .ant-input-group-addon:first-child .ant-select .ant-select-selector {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .ant-input-group-addon:first-child {
        border-right: 0;
    }
    .ant-input-group-addon:last-child {
        border-left: 0;
    }
    .ant-input-group > .ant-input:last-child,
    .ant-input-group-addon:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
    .ant-input-group-addon:last-child .ant-select .ant-select-selector {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
    .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
    .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
        border-right-width: 1px;
    }
    .ant-input-group.ant-input-group-compact > * {
        border-radius: 0;
    }
    .ant-input-group.ant-input-group-compact > *:not(:last-child) {
        border-right-width: 1px;
    }
    .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
    .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
    .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
    .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
    .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
    .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input,
    .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
        border-right-width: 1px;
        border-radius: 0;
    }
    .ant-input-group.ant-input-group-compact > *:first-child,
    .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
    .ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
    .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
    .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
    .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
    .ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }
    .ant-input-group.ant-input-group-compact > *:last-child,
    .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
    .ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
    .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
    .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
    .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
    .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
    .ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
        border-right-width: 1px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
    .ant-input-group > .ant-input-rtl:first-child,
    .ant-input-group-rtl .ant-input-group-addon:first-child {
        border-radius: 0 2px 2px 0;
    }
    .ant-input-group-rtl .ant-input-group-addon:first-child {
        border-right: 1px solid #d9d9d9;
        border-left: 0;
    }
    .ant-input-group-rtl .ant-input-group-addon:last-child {
        border-right: 0;
        border-left: 1px solid #d9d9d9;
    }
    .ant-input-group-rtl.ant-input-group > .ant-input:last-child,
    .ant-input-group-rtl.ant-input-group-addon:last-child {
        border-radius: 2px 0 0 2px;
    }
    .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
        border-radius: 2px 0 0 2px;
    }
    .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-radius: 0 2px 2px 0;
    }
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
        border-left-width: 1px;
    }
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
        border-radius: 0 2px 2px 0;
    }
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
    .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
        border-left-width: 1px;
        border-radius: 2px 0 0 2px;
    }
    .ant-input-password-icon {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-input-password-icon:hover {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-input-textarea-show-count::after {
        color: #d9d9d9;
    }
    .ant-input-search-icon {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-input-search-icon:hover {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-input-search-icon::before {
        border-left: 1px solid #d9d9d9;
    }
    .ant-input-search-enter-button input {
        border-right: 0;
    }
    .ant-input-search-enter-button input:hover,
    .ant-input-search-enter-button input:focus {
        border-color: #40a9ff;
    }
    .ant-input-search-enter-button.ant-input-affix-wrapper {
        border-right: 0;
    }
    .ant-input-search-enter-button + .ant-input-group-addon,
    .ant-input-search-enter-button input + .ant-input-group-addon {
        border: 0;
    }
    .ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
    .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
        border: none;
    }
    .ant-input-search-rtl .ant-input-search-icon::before {
        border-left: none;
    }
    .ant-input-search-rtl .ant-input-search-icon::after {
        border-right: 1px solid #d9d9d9;
    }
    .ant-input-search-rtl.ant-input-search-enter-button input {
        border-right: 1px solid #d9d9d9;
        border-left: 0;
    }
    .ant-input-search-rtl.ant-input-search-enter-button input:hover,
    .ant-input-search-rtl.ant-input-search-enter-button input:focus {
        border-color: #40a9ff;
    }
    .ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper {
        border-right: 1px solid #d9d9d9;
        border-left: 0;
    }
    .ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper:hover,
    .ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper:focus {
        border-color: #40a9ff;
    }
    .ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon,
    .ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon {
        border: 0;
    }
    .ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
    .ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
        border-radius: 2px 0 0 2px;
    }
    .ant-input-number {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-input-number::placeholder {
        color: #bfbfbf;
    }
    .ant-input-number:hover {
        border-color: #40a9ff;
        border-right-width: 1px !important;
    }
    .ant-input-rtl .ant-input-number:hover {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-input-number:focus,
    .ant-input-number-focused {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-input-rtl .ant-input-number:focus,
    .ant-input-rtl .ant-input-number-focused {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-input-number-disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
    }
    .ant-input-number-disabled:hover {
        border-color: #d9d9d9;
        border-right-width: 1px !important;
    }
    .ant-input-number[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
    }
    .ant-input-number[disabled]:hover {
        border-color: #d9d9d9;
        border-right-width: 1px !important;
    }
    .ant-input-number-borderless,
    .ant-input-number-borderless:hover,
    .ant-input-number-borderless:focus,
    .ant-input-number-borderless-focused,
    .ant-input-number-borderless-disabled,
    .ant-input-number-borderless[disabled] {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
    .ant-input-number-handler {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-input-number-handler:active {
        background: #f4f4f4;
    }
    .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
    .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
        color: #40a9ff;
    }
    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
        color: inherit;
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-input-number:hover {
        border-color: #40a9ff;
        border-right-width: 1px !important;
    }
    .ant-input-number-focused {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-input-rtl .ant-input-number-focused {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-input-number-disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
    }
    .ant-input-number-disabled:hover {
        border-color: #d9d9d9;
        border-right-width: 1px !important;
    }
    .ant-input-number-input {
        background-color: transparent;
        border: 0;
        border-radius: 2px;
    }
    .ant-input-number-input::placeholder {
        color: #bfbfbf;
    }
    .ant-input-number-handler-wrap {
        background: #fff;
        border-left: 1px solid #d9d9d9;
        border-radius: 0 2px 2px 0;
    }
    .ant-input-number-handler-up {
        border-top-right-radius: 2px;
    }
    .ant-input-number-handler-down {
        border-top: 1px solid #d9d9d9;
        border-bottom-right-radius: 2px;
    }
    .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-input-number-rtl .ant-input-number-handler-wrap {
        border-right: 1px solid #d9d9d9;
        border-left: 0;
        border-radius: 2px 0 0 2px;
    }
    .ant-layout {
        background: #f0f2f5;
    }
    .ant-layout-header {
        color: rgba(0, 0, 0, 0.85);
        // background: #001529;
    }
    // .ant-layout-footer {
    //     color: rgba(0, 0, 0, 0.85);
    //     background: #f0f2f5;
    // }

    .ant-layout-footer {
        color: rgba(0, 0, 0, 0.85);
        padding: 12px 0px;
        background-color: #fafafa;
        border-top: 1px solid #e0e3ea;
        text-align: center;
        line-height: 14px;
    }

    .ant-layout-sider {
        background: #001529;
    }
    .ant-layout-sider-trigger {
        color: #fff;
        background: #002140;
    }
    .ant-layout-sider-zero-width-trigger {
        color: #fff;
        background: #001529;
        border-radius: 0 2px 2px 0;
    }
    .ant-layout-sider-zero-width-trigger::after {
        background: transparent;
    }
    .ant-layout-sider-zero-width-trigger:hover::after {
        background: rgba(255, 255, 255, 0.1);
    }
    .ant-layout-sider-zero-width-trigger-right {
        border-radius: 2px 0 0 2px;
    }
    .ant-layout-sider-light {
        background: #fff;
    }
    .ant-layout-sider-light .ant-layout-sider-trigger {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
    }
    .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
    }
    .ant-list {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-list-empty-text {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-list-item {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-list-item-meta-content {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-list-item-meta-title {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-list-item-meta-title > a {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-list-item-meta-title > a:hover {
        color: #1890ff;
    }
    .ant-list-item-meta-description {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-list-item-action > li {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-list-item-action-split {
        background-color: #f0f0f0;
    }
    .ant-list-header {
        background: transparent;
    }
    .ant-list-footer {
        background: transparent;
    }
    .ant-list-empty {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-list-split .ant-list-item {
        border-bottom: 1px solid #f0f0f0;
    }
    .ant-list-split .ant-list-item:last-child {
        border-bottom: none;
    }
    .ant-list-split .ant-list-header {
        border-bottom: 1px solid #f0f0f0;
    }
    .ant-list-split.ant-list-empty .ant-list-footer {
        border-top: 1px solid #f0f0f0;
    }
    .ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
        border-bottom: 1px solid #f0f0f0;
    }
    .ant-list-vertical .ant-list-item-meta-title {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-list-grid .ant-col > .ant-list-item {
        border-bottom: none;
    }
    .ant-list-bordered {
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-mentions {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-mentions::placeholder {
        color: #bfbfbf;
    }
    .ant-mentions:hover {
        border-color: #40a9ff;
        border-right-width: 1px !important;
    }
    .ant-input-rtl .ant-mentions:hover {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-mentions:focus,
    .ant-mentions-focused {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-input-rtl .ant-mentions:focus,
    .ant-input-rtl .ant-mentions-focused {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-mentions-disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
    }
    .ant-mentions-disabled:hover {
        border-color: #d9d9d9;
        border-right-width: 1px !important;
    }
    .ant-mentions[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
    }
    .ant-mentions[disabled]:hover {
        border-color: #d9d9d9;
        border-right-width: 1px !important;
    }
    .ant-mentions-borderless,
    .ant-mentions-borderless:hover,
    .ant-mentions-borderless:focus,
    .ant-mentions-borderless-focused,
    .ant-mentions-borderless-disabled,
    .ant-mentions-borderless[disabled] {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
    .ant-mentions-disabled > textarea {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
    }
    .ant-mentions-disabled > textarea:hover {
        border-color: #d9d9d9;
        border-right-width: 1px !important;
    }
    .ant-mentions-focused {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-input-rtl .ant-mentions-focused {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-mentions > textarea {
        border: none;
    }
    .ant-mentions > textarea::placeholder {
        color: #bfbfbf;
    }
    .ant-mentions-measure {
        color: transparent;
    }
    .ant-mentions-dropdown {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .ant-mentions-dropdown-menu-item {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-mentions-dropdown-menu-item:hover {
        background-color: #f5f5f5;
    }
    .ant-mentions-dropdown-menu-item:first-child {
        border-radius: 2px 2px 0 0;
    }
    .ant-mentions-dropdown-menu-item:last-child {
        border-radius: 0 0 2px 2px;
    }
    .ant-mentions-dropdown-menu-item-disabled {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-mentions-dropdown-menu-item-disabled:hover {
        color: rgba(0, 0, 0, 0.25);
        background-color: #fff;
    }
    .ant-mentions-dropdown-menu-item-selected {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fafafa;
    }
    .ant-mentions-dropdown-menu-item-active {
        background-color: #f5f5f5;
    }
    .ant-menu-item-danger.ant-menu-item {
        color: #ff4d4f;
    }
    .ant-menu-item-danger.ant-menu-item:hover,
    .ant-menu-item-danger.ant-menu-item-active {
        color: #ff4d4f;
    }
    .ant-menu-item-danger.ant-menu-item:active {
        background: #69c0ff;
    }
    .ant-menu-item-danger.ant-menu-item-selected {
        color: #ff4d4f;
    }
    .ant-menu-item-danger.ant-menu-item-selected > a,
    .ant-menu-item-danger.ant-menu-item-selected > a:hover {
        color: #ff4d4f;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
        background-color: #69c0ff;
    }
    .ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
        border-right-color: #ff4d4f;
    }
    .ant-menu-dark .ant-menu-item-danger.ant-menu-item,
    .ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
    .ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
        color: #ff4d4f;
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
        color: #fff;
        background-color: #ff4d4f;
    }
    .ant-menu {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .ant-menu-item-group-title {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-menu-submenu-selected {
        color: #1890ff;
    }
    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
        background: #e6f7ff;
    }
    .ant-menu-item a {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-menu-item a:hover {
        color: #1890ff;
    }
    .ant-menu-item a::before {
        background-color: transparent;
    }
    .ant-menu-item > .ant-badge a {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-menu-item > .ant-badge a:hover {
        color: #1890ff;
    }
    .ant-menu-item-divider {
        background-color: #f0f0f0;
    }
    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
        color: #1890ff;
    }
    .ant-menu-horizontal > .ant-menu-item:hover,
    .ant-menu-horizontal > .ant-menu-item-active,
    .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
        background-color: transparent;
    }
    .ant-menu-item-selected {
        color: #1890ff;
    }
    .ant-menu-item-selected a,
    .ant-menu-item-selected a:hover {
        color: #1890ff;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: #e6f7ff;
    }
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
        border-right: none;
    }
    .ant-menu-vertical-right {
        border-left: 1px solid #f0f0f0;
    }
    .ant-menu-vertical.ant-menu-sub,
    .ant-menu-vertical-left.ant-menu-sub,
    .ant-menu-vertical-right.ant-menu-sub {
        border-right: 0;
    }
    .ant-menu-vertical.ant-menu-sub .ant-menu-item,
    .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
    .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
        border-right: 0;
    }
    .ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
    .ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
    .ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
        border-right: 0;
    }
    .ant-menu > .ant-menu-item-divider {
        background-color: #f0f0f0;
    }
    .ant-menu-submenu-popup {
        border-radius: 2px;
        box-shadow: none;
    }
    .ant-menu-submenu > .ant-menu {
        background-color: #fff;
        border-radius: 2px;
    }
    .ant-menu-submenu-popup > .ant-menu {
        background-color: #fff;
    }
    .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85));
        border-radius: 2px;
    }
    .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
        background: linear-gradient(to right, #1890ff, #1890ff);
    }
    .ant-menu-vertical .ant-menu-submenu-selected,
    .ant-menu-vertical-left .ant-menu-submenu-selected,
    .ant-menu-vertical-right .ant-menu-submenu-selected {
        color: #1890ff;
    }
    .ant-menu-horizontal {
        border: 0;
        border-bottom: 1px solid #f0f0f0;
        box-shadow: none;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
        color: #1890ff;
        border-bottom: 2px solid #1890ff;
    }
    .ant-menu-horizontal > .ant-menu-item,
    .ant-menu-horizontal > .ant-menu-submenu {
        border-bottom: 2px solid transparent;
    }
    .ant-menu-horizontal > .ant-menu-item a {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-menu-horizontal > .ant-menu-item a:hover {
        color: #1890ff;
    }
    .ant-menu-horizontal > .ant-menu-item-selected a {
        color: #1890ff;
    }
    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
        border-right: 3px solid #1890ff;
    }
    .ant-menu-inline-collapsed-tooltip a {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-menu-root.ant-menu-vertical,
    .ant-menu-root.ant-menu-vertical-left,
    .ant-menu-root.ant-menu-vertical-right,
    .ant-menu-root.ant-menu-inline {
        box-shadow: none;
    }
    .ant-menu-sub.ant-menu-inline {
        border: 0;
        border-radius: 0;
        box-shadow: none;
    }
    .ant-menu-item-disabled,
    .ant-menu-submenu-disabled {
        color: rgba(0, 0, 0, 0.25) !important;
        background: none;
        border-color: transparent !important;
    }
    .ant-menu-item-disabled a,
    .ant-menu-submenu-disabled a {
        color: rgba(0, 0, 0, 0.25) !important;
    }
    .ant-menu-item-disabled > .ant-menu-submenu-title,
    .ant-menu-submenu-disabled > .ant-menu-submenu-title {
        color: rgba(0, 0, 0, 0.25) !important;
    }
    .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
        background: rgba(0, 0, 0, 0.25) !important;
    }
    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
        color: rgba(255, 255, 255, 0.65);
        background: #001529;
    }
    .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
        background: #fff;
    }
    .ant-menu-dark.ant-menu-submenu-popup {
        background: transparent;
    }
    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
        background: #000c17;
    }
    .ant-menu-dark.ant-menu-horizontal {
        border-bottom: 0;
    }
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
        border-color: #001529;
        border-bottom: 0;
    }
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
        background-color: #1890ff;
    }
    .ant-menu-dark .ant-menu-item,
    .ant-menu-dark .ant-menu-item-group-title,
    .ant-menu-dark .ant-menu-item > a,
    .ant-menu-dark .ant-menu-item > span > a {
        color: rgba(255, 255, 255, 0.65);
    }
    .ant-menu-dark.ant-menu-inline,
    .ant-menu-dark.ant-menu-vertical,
    .ant-menu-dark.ant-menu-vertical-left,
    .ant-menu-dark.ant-menu-vertical-right {
        border-right: 0;
    }
    .ant-menu-dark.ant-menu-inline .ant-menu-item,
    .ant-menu-dark.ant-menu-vertical .ant-menu-item,
    .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
    .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
        border-right: 0;
    }
    .ant-menu-dark.ant-menu-inline .ant-menu-item::after,
    .ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
    .ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
        border-right: 0;
    }
    .ant-menu-dark .ant-menu-item:hover,
    .ant-menu-dark .ant-menu-item-active,
    .ant-menu-dark .ant-menu-submenu-active,
    .ant-menu-dark .ant-menu-submenu-open,
    .ant-menu-dark .ant-menu-submenu-selected,
    .ant-menu-dark .ant-menu-submenu-title:hover {
        color: #fff;
        background-color: transparent;
    }
    .ant-menu-dark .ant-menu-item:hover > a,
    .ant-menu-dark .ant-menu-item-active > a,
    .ant-menu-dark .ant-menu-submenu-active > a,
    .ant-menu-dark .ant-menu-submenu-open > a,
    .ant-menu-dark .ant-menu-submenu-selected > a,
    .ant-menu-dark .ant-menu-submenu-title:hover > a,
    .ant-menu-dark .ant-menu-item:hover > span > a,
    .ant-menu-dark .ant-menu-item-active > span > a,
    .ant-menu-dark .ant-menu-submenu-active > span > a,
    .ant-menu-dark .ant-menu-submenu-open > span > a,
    .ant-menu-dark .ant-menu-submenu-selected > span > a,
    .ant-menu-dark .ant-menu-submenu-title:hover > span > a {
        color: #fff;
    }
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
        background: #fff;
    }
    .ant-menu-dark .ant-menu-item:hover {
        background-color: transparent;
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: #1890ff;
    }
    .ant-menu-dark .ant-menu-item-selected {
        color: #fff;
        border-right: 0;
    }
    .ant-menu-dark .ant-menu-item-selected::after {
        border-right: 0;
    }
    .ant-menu-dark .ant-menu-item-selected > a,
    .ant-menu-dark .ant-menu-item-selected > span > a,
    .ant-menu-dark .ant-menu-item-selected > a:hover,
    .ant-menu-dark .ant-menu-item-selected > span > a:hover {
        color: #fff;
    }
    .ant-menu-dark .ant-menu-item-selected .anticon {
        color: #fff;
    }
    .ant-menu-dark .ant-menu-item-selected .anticon + span {
        color: #fff;
    }
    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
        background-color: #1890ff;
    }
    .ant-menu-dark .ant-menu-item-disabled,
    .ant-menu-dark .ant-menu-submenu-disabled,
    .ant-menu-dark .ant-menu-item-disabled > a,
    .ant-menu-dark .ant-menu-submenu-disabled > a,
    .ant-menu-dark .ant-menu-item-disabled > span > a,
    .ant-menu-dark .ant-menu-submenu-disabled > span > a {
        color: rgba(255, 255, 255, 0.35) !important;
    }
    .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
    .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
        color: rgba(255, 255, 255, 0.35) !important;
    }
    .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
        background: rgba(255, 255, 255, 0.35) !important;
    }
    .ant-menu-rtl.ant-menu-inline,
    .ant-menu-rtl.ant-menu-vertical {
        border-right: none;
        border-left: 1px solid #f0f0f0;
    }
    .ant-menu-rtl.ant-menu-dark.ant-menu-inline,
    .ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
        border-left: none;
    }
    .ant-menu-sub.ant-menu-inline {
        border: 0;
    }
    .ant-message {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-message-notice-content {
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .ant-message-success .anticon {
        color: #52c41a;
    }
    .ant-message-error .anticon {
        color: #ff4d4f;
    }
    .ant-message-warning .anticon {
        color: #faad14;
    }
    .ant-message-info .anticon,
    .ant-message-loading .anticon {
        color: #1890ff;
    }
    .ant-modal {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-modal-title {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-modal-content {
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
        border-radius: 2px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .ant-modal-close {
        color: rgba(0, 0, 0, 0.45);
        background: transparent;
        border: 0;
    }
    .ant-modal-close:focus,
    .ant-modal-close:hover {
        color: rgba(0, 0, 0, 0.75);
    }
    .ant-modal-header {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-bottom: 1px solid #f0f0f0;
        border-radius: 2px 2px 0 0;
    }
    .ant-modal-footer {
        background: transparent;
        border-top: 1px solid #f0f0f0;
        border-radius: 0 0 2px 2px;
    }
    .ant-modal-confirm-body .ant-modal-confirm-title {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-modal-confirm-body .ant-modal-confirm-content {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
        color: #ff4d4f;
    }
    .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
    .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
        color: #faad14;
    }
    .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
        color: #1890ff;
    }
    .ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
        color: #52c41a;
    }
    .ant-notification {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-notification-hook-holder,
    .ant-notification-notice {
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .ant-notification-hook-holder > .ant-notification-notice {
        box-shadow: none;
    }
    .ant-notification-notice-message {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-notification-notice-message-single-line-auto-margin {
        background-color: transparent;
    }
    .anticon.ant-notification-notice-icon-success {
        color: #52c41a;
    }
    .anticon.ant-notification-notice-icon-info {
        color: #1890ff;
    }
    .anticon.ant-notification-notice-icon-warning {
        color: #faad14;
    }
    .anticon.ant-notification-notice-icon-error {
        color: #ff4d4f;
    }
    .ant-notification-notice-close {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-notification-notice-close:hover {
        color: rgba(0, 0, 0, 0.67);
    }
    .ant-page-header {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fff;
    }
    .ant-page-header-ghost {
        background-color: inherit;
    }
    .ant-page-header-back-button {
        color: #1890ff;
        color: #000;
    }
    .ant-page-header-back-button:focus,
    .ant-page-header-back-button:hover {
        color: #40a9ff;
    }
    .ant-page-header-back-button:active {
        color: #096dd9;
    }
    .ant-page-header-heading-title {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-page-header-heading-sub-title {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
        border: none;
    }
    .ant-pagination {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-pagination-item {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-pagination-item a {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-pagination-item:focus,
    .ant-pagination-item:hover {
        border-color: #1890ff;
    }
    .ant-pagination-item:focus a,
    .ant-pagination-item:hover a {
        color: #1890ff;
    }
    .ant-pagination-item-active {
        background: #fff;
        border-color: #1890ff;
    }
    .ant-pagination-item-active a {
        color: #1890ff;
    }
    .ant-pagination-item-active:focus,
    .ant-pagination-item-active:hover {
        border-color: #40a9ff;
    }
    .ant-pagination-item-active:focus a,
    .ant-pagination-item-active:hover a {
        color: #40a9ff;
    }
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
        color: #1890ff;
    }
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
        color: rgba(0, 0, 0, 0.85);
        border-radius: 2px;
    }
    .ant-pagination-prev button,
    .ant-pagination-next button {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-pagination-prev:hover button,
    .ant-pagination-next:hover button {
        border-color: #40a9ff;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-pagination-prev:focus .ant-pagination-item-link,
    .ant-pagination-next:focus .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
        color: #1890ff;
        border-color: #1890ff;
    }
    .ant-pagination-disabled .ant-pagination-item-link,
    .ant-pagination-disabled:hover .ant-pagination-item-link,
    .ant-pagination-disabled:focus .ant-pagination-item-link {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
    }
    .ant-pagination-options-quick-jumper input {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-pagination-options-quick-jumper input::placeholder {
        color: #bfbfbf;
    }
    .ant-pagination-options-quick-jumper input:hover {
        border-color: #40a9ff;
        border-right-width: 1px !important;
    }
    .ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-pagination-options-quick-jumper input:focus,
    .ant-pagination-options-quick-jumper input-focused {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-input-rtl .ant-pagination-options-quick-jumper input:focus,
    .ant-input-rtl .ant-pagination-options-quick-jumper input-focused {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-pagination-options-quick-jumper input-disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
    }
    .ant-pagination-options-quick-jumper input-disabled:hover {
        border-color: #d9d9d9;
        border-right-width: 1px !important;
    }
    .ant-pagination-options-quick-jumper input[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
    }
    .ant-pagination-options-quick-jumper input[disabled]:hover {
        border-color: #d9d9d9;
        border-right-width: 1px !important;
    }
    .ant-pagination-options-quick-jumper input-borderless,
    .ant-pagination-options-quick-jumper input-borderless:hover,
    .ant-pagination-options-quick-jumper input-borderless:focus,
    .ant-pagination-options-quick-jumper input-borderless-focused,
    .ant-pagination-options-quick-jumper input-borderless-disabled,
    .ant-pagination-options-quick-jumper input-borderless[disabled] {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
    .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
        background-color: transparent;
        border: 0;
    }
    .ant-pagination-simple .ant-pagination-simple-pager input {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-pagination-simple .ant-pagination-simple-pager input:hover {
        border-color: #1890ff;
    }
    .ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
    }
    .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
        background: transparent;
        border-color: transparent;
    }
    .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
        background: transparent;
        border-color: transparent;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item {
        background: #f5f5f5;
        border-color: #d9d9d9;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
        color: rgba(0, 0, 0, 0.25);
        background: transparent;
        border: none;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
        background: #dbdbdb;
        border-color: transparent;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
        color: #fff;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
    }
    .ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
        background: transparent;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-popover {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-popover::after {
        background: rgba(255, 255, 255, 0.01);
    }
    .ant-popover-inner {
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 2px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    }
    .ant-popover-title {
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 1px solid #f0f0f0;
    }
    .ant-popover-inner-content {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-popover-message {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-popover-message > .anticon {
        color: #faad14;
    }
    .ant-popover-arrow {
        background: transparent;
        border-style: solid;
        border-width: 4.24264069px;
    }
    .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
        border-top-color: transparent;
        border-right-color: #fff;
        border-bottom-color: #fff;
        border-left-color: transparent;
        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    }
    .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: #fff;
        border-left-color: #fff;
        box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
    }
    .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
        border-top-color: #fff;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: #fff;
        box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
    }
    .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
        border-top-color: #fff;
        border-right-color: #fff;
        border-bottom-color: transparent;
        border-left-color: transparent;
        box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
    }
    .ant-progress {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-progress-steps-item {
        background: #f3f3f3;
    }
    .ant-progress-steps-item-active {
        background: #1890ff;
    }
    .ant-progress-inner {
        background-color: #f5f5f5;
        border-radius: 100px;
    }
    .ant-progress-success-bg,
    .ant-progress-bg {
        background-color: #1890ff;
        border-radius: 100px;
    }
    .ant-progress-success-bg {
        background-color: #52c41a;
    }
    .ant-progress-text {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-progress-status-active .ant-progress-bg::before {
        background: #fff;
        border-radius: 10px;
    }
    .ant-progress-status-exception .ant-progress-bg {
        background-color: #ff4d4f;
    }
    .ant-progress-status-exception .ant-progress-text {
        color: #ff4d4f;
    }
    .ant-progress-status-success .ant-progress-bg {
        background-color: #52c41a;
    }
    .ant-progress-status-success .ant-progress-text {
        color: #52c41a;
    }
    .ant-progress-circle .ant-progress-inner {
        background-color: transparent;
    }
    .ant-progress-circle .ant-progress-text {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-progress-circle.ant-progress-status-exception .ant-progress-text {
        color: #ff4d4f;
    }
    .ant-progress-circle.ant-progress-status-success .ant-progress-text {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-radio-group {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
        border-left: none;
    }
    .ant-radio-wrapper {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-radio {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: #1890ff;
    }
    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
    }
    .ant-radio-checked::after {
        border: 1px solid #1890ff;
        border-radius: 50%;
    }
    .ant-radio-inner {
        background-color: #fff;
        border-color: #d9d9d9;
        border-style: solid;
        border-width: 1px;
        border-radius: 100px;
    }
    .ant-radio-inner::after {
        background-color: #1890ff;
        border-top: 0;
        border-left: 0;
        border-radius: 8px;
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: #1890ff;
    }
    .ant-radio-disabled .ant-radio-inner {
        background-color: #f5f5f5;
        border-color: #d9d9d9 !important;
    }
    .ant-radio-disabled .ant-radio-inner::after {
        background-color: rgba(0, 0, 0, 0.2);
    }
    .ant-radio-disabled + span {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-radio-button-wrapper {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border: 1px solid #d9d9d9;
        border-top-width: 1.02px;
        border-left-width: 0;
    }
    .ant-radio-button-wrapper a {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: #d9d9d9;
    }
    .ant-radio-button-wrapper:first-child {
        border-left: 1px solid #d9d9d9;
        border-radius: 2px 0 0 2px;
    }
    .ant-radio-button-wrapper:last-child {
        border-radius: 0 2px 2px 0;
    }
    .ant-radio-button-wrapper:first-child:last-child {
        border-radius: 2px;
    }
    .ant-radio-button-wrapper:hover {
        color: #1890ff;
    }
    .ant-radio-button-wrapper:focus-within {
        box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: #1890ff;
        background: #fff;
        border-color: #1890ff;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: #1890ff;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
        border-color: #1890ff;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        color: #40a9ff;
        border-color: #40a9ff;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
        background-color: #40a9ff;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
        color: #096dd9;
        border-color: #096dd9;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
        background-color: #096dd9;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: #fff;
        background: #1890ff;
        border-color: #1890ff;
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        color: #fff;
        background: #40a9ff;
        border-color: #40a9ff;
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
        color: #fff;
        background: #096dd9;
        border-color: #096dd9;
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
    }
    .ant-radio-button-wrapper-disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
    }
    .ant-radio-button-wrapper-disabled:first-child,
    .ant-radio-button-wrapper-disabled:hover {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
    }
    .ant-radio-button-wrapper-disabled:first-child {
        border-left-color: #d9d9d9;
    }
    .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
        color: rgba(0, 0, 0, 0.25);
        background-color: #e6e6e6;
        border-color: #d9d9d9;
        box-shadow: none;
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
        border-right-width: 0;
        border-left-width: 1px;
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
        border-right: 1px solid #d9d9d9;
        border-radius: 0 2px 2px 0;
    }
    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
        border-right-color: #40a9ff;
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
        border-radius: 2px 0 0 2px;
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
        border-right-color: #d9d9d9;
    }
    .ant-rate {
        color: rgba(0, 0, 0, 0.85);
        color: #fadb14;
    }
    .ant-rate-star {
        color: inherit;
    }
    .ant-rate-star-first,
    .ant-rate-star-second {
        color: #f0f0f0;
    }
    .ant-rate-star-half .ant-rate-star-first,
    .ant-rate-star-full .ant-rate-star-second {
        color: inherit;
    }
    .ant-result-success .ant-result-icon > .anticon {
        color: #52c41a;
    }
    .ant-result-error .ant-result-icon > .anticon {
        color: #ff4d4f;
    }
    .ant-result-info .ant-result-icon > .anticon {
        color: #1890ff;
    }
    .ant-result-warning .ant-result-icon > .anticon {
        color: #faad14;
    }
    .ant-result-title {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-result-subtitle {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-result-content {
        background-color: #fafafa;
    }
    .ant-select-selection__clear {
        color: rgba(0, 0, 0, 0.45);
        background: transparent;
    }

    .ant-select-selection__clear:hover {
        color: #000;
    }

    .ant-select-single.ant-select-open .ant-select-selection-item {
        color: #bfbfbf;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        background: transparent;
        border: none;
    }
    .ant-select-multiple .ant-select-selector {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-select-disabled.ant-select-multiple .ant-select-selector {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
    }
    .ant-select-multiple .ant-select-selector .ant-select-selection-search-input {
        background: transparent;
        border: none;
    }
    .ant-select-disabled.ant-select-multiple .ant-select-selector {
        background: #f5f5f5;
    }
    .ant-select-multiple .ant-select-selection-item {
        background: #f5f5f5;
        border: 1px solid #f0f0f0;
        border-radius: 2px;
    }
    .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
        color: #bfbfbf;
        border-color: #d9d9d9;
    }
    .ant-select-multiple .ant-select-selection-item-remove {
        color: inherit;
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-select-multiple .ant-select-selection-item-remove:hover {
        color: rgba(0, 0, 0, 0.75);
    }
    .ant-select {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #40a9ff;
        border-right-width: 1px !important;
    }
    .ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-right-width: 0;
        border-left-width: 1px !important;
    }
    .ant-select-selection-placeholder {
        color: #bfbfbf;
    }
    .ant-select-arrow {
        color: inherit;
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-select-clear {
        color: rgba(0, 0, 0, 0.25);
        background: #fff;
    }
    .ant-select-clear:hover {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-select-dropdown {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .ant-select-dropdown-empty {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-select-item-empty {
        color: rgba(0, 0, 0, 0.85);
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-select-item {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-select-item-group {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: #f5f5f5;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: rgba(0, 0, 0, 0.85);
        background-color: #f5f5f5;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
        color: #1890ff;
    }
    .ant-select-item-option-disabled {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-select-borderless .ant-select-selector {
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }
    .ant-skeleton-header .ant-skeleton-avatar {
        background: #1890ff;
    }
    .ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
        border-radius: 50%;
    }
    .ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
        border-radius: 50%;
    }
    .ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
        border-radius: 50%;
    }
    .ant-skeleton-content .ant-skeleton-title {
        background: #1890ff;
    }
    .ant-skeleton-content .ant-skeleton-paragraph > li {
        background: #1890ff;
    }
    .ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
    .ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
        border-radius: 100px;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
    .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
        background: linear-gradient(90deg, #1890ff 25%, #1789f2 37%, #1890ff 63%);
        background-size: 400% 100%;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
        background: linear-gradient(90deg, #1890ff 25%, #1789f2 37%, #1890ff 63%);
        background-size: 400% 100%;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-button {
        background: linear-gradient(90deg, #1890ff 25%, #1789f2 37%, #1890ff 63%);
        background-size: 400% 100%;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-input {
        background: linear-gradient(90deg, #1890ff 25%, #1789f2 37%, #1890ff 63%);
        background-size: 400% 100%;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-image {
        background: linear-gradient(90deg, #1890ff 25%, #1789f2 37%, #1890ff 63%);
        background-size: 400% 100%;
    }
    .ant-skeleton-element .ant-skeleton-button {
        background: #1890ff;
        border-radius: 2px;
    }
    .ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
        border-radius: 50%;
    }
    .ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
        border-radius: 32px;
    }
    .ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
        border-radius: 50%;
    }
    .ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
        border-radius: 40px;
    }
    .ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
        border-radius: 50%;
    }
    .ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
        border-radius: 24px;
    }
    .ant-skeleton-element .ant-skeleton-avatar {
        background: #1890ff;
    }
    .ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
        border-radius: 50%;
    }
    .ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
        border-radius: 50%;
    }
    .ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
        border-radius: 50%;
    }
    .ant-skeleton-element .ant-skeleton-input {
        background: #1890ff;
    }
    .ant-skeleton-element .ant-skeleton-image {
        background: #1890ff;
    }
    .ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
        border-radius: 50%;
    }
    .ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
        border-radius: 50%;
    }
    .ant-slider {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-slider-rail {
        background-color: #f5f5f5;
        border-radius: 2px;
    }
    .ant-slider-track {
        background-color: #91d5ff;
        border-radius: 2px;
    }
    .ant-slider-handle {
        background-color: #fff;
        border: solid 2px #91d5ff;
        border-radius: 50%;
        box-shadow: 0;
    }
    .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
        border-color: #46a6ff;
        box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
    }
    .ant-slider-handle:focus {
        border-color: #46a6ff;
        box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
    }
    .ant-slider-handle.ant-tooltip-open {
        border-color: #1890ff;
    }
    .ant-slider:hover .ant-slider-rail {
        background-color: #e1e1e1;
    }
    .ant-slider:hover .ant-slider-track {
        background-color: #69c0ff;
    }
    .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: #69c0ff;
    }
    .ant-slider-mark-text {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-slider-mark-text-active {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-slider-step {
        background: transparent;
    }
    .ant-slider-dot {
        background-color: #fff;
        border: 2px solid #f0f0f0;
        border-radius: 50%;
    }
    .ant-slider-dot-active {
        border-color: #8cc8ff;
    }
    .ant-slider-disabled .ant-slider-track {
        background-color: rgba(0, 0, 0, 0.25) !important;
    }
    .ant-slider-disabled .ant-slider-handle,
    .ant-slider-disabled .ant-slider-dot {
        background-color: #fff;
        border-color: rgba(0, 0, 0, 0.25) !important;
        box-shadow: none;
    }
    .ant-spin {
        color: rgba(0, 0, 0, 0.85);
        color: #1890ff;
    }
    .ant-spin-container::after {
        background: #fff;
    }
    .ant-spin-tip {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-spin-dot-item {
        background-color: #1890ff;
        border-radius: 100%;
    }
    .ant-statistic {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-statistic-title {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-statistic-content {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-steps {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-steps-item-icon {
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 32px;
    }
    .ant-steps-item-icon .ant-steps-icon {
        color: #1890ff;
    }
    .ant-steps-item-tail::after {
        background: #f0f0f0;
        border-radius: 1px;
    }
    .ant-steps-item-title {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-steps-item-title::after {
        background: #f0f0f0;
    }
    .ant-steps-item-subtitle {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-steps-item-description {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-steps-item-wait .ant-steps-item-icon {
        background-color: #fff;
        border-color: rgba(0, 0, 0, 0.25);
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background: rgba(0, 0, 0, 0.25);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: #f0f0f0;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: #f0f0f0;
    }
    .ant-steps-item-process .ant-steps-item-icon {
        background-color: #fff;
        border-color: #1890ff;
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
        color: #1890ff;
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background: #1890ff;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: #f0f0f0;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: #f0f0f0;
    }
    .ant-steps-item-process .ant-steps-item-icon {
        background: #1890ff;
    }
    .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
        color: #fff;
    }
    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: #fff;
        border-color: #1890ff;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: #1890ff;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background: #1890ff;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: #1890ff;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: #1890ff;
    }
    .ant-steps-item-error .ant-steps-item-icon {
        background-color: #fff;
        border-color: #ff4d4f;
    }
    .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
        color: #ff4d4f;
    }
    .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background: #ff4d4f;
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: #ff4d4f;
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: #f0f0f0;
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: #ff4d4f;
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: #f0f0f0;
    }
    .ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
        background: #ff4d4f;
    }
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
        color: #1890ff;
    }
    .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
        border-color: #1890ff;
    }
    .ant-steps
        .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
        > .ant-steps-item-container[role='button']:hover
        .ant-steps-item-icon
        .ant-steps-icon {
        color: #1890ff;
    }
    .ant-steps-item-custom .ant-steps-item-icon {
        background: none;
        border: 0;
    }
    .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
        color: #1890ff;
    }
    .ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
        background: none;
    }
    .ant-steps-small .ant-steps-item-icon {
        border-radius: 24px;
    }
    .ant-steps-small .ant-steps-item-description {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
        background: none;
        border: 0;
        border-radius: 0;
    }
    .ant-steps-dot .ant-steps-item-icon,
    .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        background: transparent;
        border: 0;
    }
    .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
    .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
        border-radius: 100px;
    }
    .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
    .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
        background: rgba(0, 0, 0, 0.001);
    }
    .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
        background: none;
    }
    .ant-steps-navigation .ant-steps-item::after {
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-bottom: none;
        border-left: none;
    }
    .ant-steps-navigation .ant-steps-item::before {
        background-color: #1890ff;
    }
    .ant-switch {
        color: rgba(0, 0, 0, 0.85);
        background-color: rgba(0, 0, 0, 0.25);
        border: 0;
        border-radius: 100px;
    }
    .ant-switch:focus {
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }
    .ant-switch-checked:focus {
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-switch:focus:hover {
        box-shadow: none;
    }
    .ant-switch-checked {
        background-color: #1890ff;
    }
    .ant-switch-loading *,
    .ant-switch-disabled * {
        box-shadow: none;
    }
    .ant-switch-inner {
        color: #fff;
    }
    .ant-switch-handle::before {
        background-color: #fff;
        border-radius: 9px;
        box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    }
    .ant-switch-loading-icon {
        color: rgba(0, 0, 0, 0.65);
    }
    .ant-switch-checked .ant-switch-loading-icon {
        color: #1890ff;
    }
    .ant-table-small .ant-table-thead > tr > th {
        background-color: #fafafa;
    }
    .ant-table.ant-table-bordered > .ant-table-title {
        border: 1px solid #f0f0f0;
        border-bottom: 0;
    }
    .ant-table.ant-table-bordered > .ant-table-container {
        border: 1px solid #f0f0f0;
        border-right: 0;
        border-bottom: 0;
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
        border-right: 1px solid #f0f0f0;
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th {
        border-bottom: 1px solid #f0f0f0;
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
        border-right: 1px solid #f0f0f0;
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
        border-right: 1px solid #f0f0f0;
    }
    .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
    .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
        border-right: 0;
    }
    .ant-table.ant-table-bordered > .ant-table-footer {
        border: 1px solid #f0f0f0;
        border-top: 0;
    }
    .ant-table-cell .ant-table-container:first-child {
        border-top: 0;
    }
    .ant-table-cell-scrollbar {
        box-shadow: 0 1px 0 1px #fafafa;
    }
    .ant-table {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-radius: 2px;
    }
    .ant-table table {
        border-radius: 2px 2px 0 0;
        border-collapse: separate;
        border-spacing: 0;
    }
    .ant-table-footer {
        color: rgba(0, 0, 0, 0.85);
        background: #fafafa;
    }
    .ant-table-thead > tr > th {
        color: rgba(0, 0, 0, 0.85);
        background: #fafafa;
        border-bottom: 1px solid #f0f0f0;
    }
    .ant-table-thead > tr:not(:last-child) > th[colspan] {
        border-bottom: 0;
    }
    .ant-table-tbody > tr > td {
        border-bottom: 1px solid #f0f0f0;
    }
    .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
        border-bottom: 0;
    }
    .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
    .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
        border-radius: 0;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
        background: #fafafa;
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
        background: #e6f7ff;
        border-color: rgba(0, 0, 0, 0.03);
    }
    .ant-table-tbody > tr.ant-table-row-selected:hover > td {
        background: #dcf4ff;
    }
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
        border-bottom: 1px solid #f0f0f0;
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover {
        background: #f2f2f2;
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
        background: #f7f7f7;
    }
    .ant-table-thead th.ant-table-column-sort {
        background: #f5f5f5;
    }
    td.ant-table-column-sort {
        background: #fafafa;
    }
    .ant-table-column-sorter {
        color: #bfbfbf;
    }
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
        color: #1890ff;
    }
    .ant-table-filter-trigger-container-open,
    .ant-table-filter-trigger-container:hover,
    .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
        background: #e5e5e5;
    }
    .ant-table-filter-trigger {
        color: #bfbfbf;
    }
    .ant-table-filter-trigger-container-open .ant-table-filter-trigger,
    .ant-table-filter-trigger:hover {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-table-filter-trigger.active {
        color: #1890ff;
    }
    .ant-table-filter-dropdown {
        color: rgba(0, 0, 0, 0.85);
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .ant-table-filter-dropdown .ant-dropdown-menu {
        border: 0;
        box-shadow: none;
    }
    .ant-table-filter-dropdown-btns {
        background-color: inherit;
        border-top: 1px solid #f0f0f0;
    }
    .ant-table-selection-extra .anticon {
        color: #bfbfbf;
    }
    .ant-table-selection-extra .anticon:hover {
        color: #a6a6a6;
    }
    .ant-table-row-expand-icon {
        color: #1890ff;
        color: inherit;
        background: #fff;
        border: 1px solid #f0f0f0;
        border-radius: 2px;
    }
    .ant-table-row-expand-icon:focus,
    .ant-table-row-expand-icon:hover {
        color: #40a9ff;
    }
    .ant-table-row-expand-icon:active {
        color: #096dd9;
    }
    .ant-table-row-expand-icon:focus,
    .ant-table-row-expand-icon:hover,
    .ant-table-row-expand-icon:active {
        border-color: currentColor;
    }
    .ant-table-row-expand-icon::before,
    .ant-table-row-expand-icon::after {
        background: currentColor;
    }
    .ant-table-row-expand-icon-spaced {
        background: transparent;
        border: 0;
    }
    tr.ant-table-expanded-row > td,
    tr.ant-table-expanded-row:hover > td {
        background: #fbfbfb;
    }
    .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
        background: #fff;
    }
    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
        background: #fff;
    }
    .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
        box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
    }
    .ant-table-ping-left .ant-table-cell-fix-left-first::after,
    .ant-table-ping-left .ant-table-cell-fix-left-last::after {
        box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
    }
    .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
        box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
    }
    .ant-table-ping-right .ant-table-cell-fix-right-first::after,
    .ant-table-ping-right .ant-table-cell-fix-right-last::after {
        box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
    }
    .ant-table-sticky-scroll {
        background: #ffffff;
        border-top: 1px solid #f0f0f0;
    }
    .ant-table-sticky-scroll-bar {
        background-color: rgba(0, 0, 0, 0.35);
        border-radius: 4px;
    }
    .ant-table-sticky-scroll-bar:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }
    .ant-table-sticky-scroll-bar-active {
        background-color: rgba(0, 0, 0, 0.8);
    }
    .ant-table-title {
        border-radius: 2px 2px 0 0;
    }
    .ant-table-title + .ant-table-container {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
        border-radius: 0;
    }
    .ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
        border-radius: 0;
    }
    .ant-table-container {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
        border-top-left-radius: 2px;
    }
    .ant-table-container table > thead > tr:first-child th:last-child {
        border-top-right-radius: 2px;
    }
    .ant-table-footer {
        border-radius: 0 0 2px 2px;
    }
    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
        border-bottom: 1px solid #f0f0f0;
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
        box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
        box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
        box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
        box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
    }
    .ant-tabs-left > .ant-tabs-content-holder,
    .ant-tabs-left > div > .ant-tabs-content-holder {
        border-left: 1px solid #f0f0f0;
    }
    .ant-tabs-right > .ant-tabs-content-holder,
    .ant-tabs-right > div > .ant-tabs-content-holder {
        border-right: 1px solid #f0f0f0;
    }
    .ant-tabs-dropdown {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-tabs-dropdown-menu {
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 2px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .ant-tabs-dropdown-menu-item {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-tabs-dropdown-menu-item:hover {
        background: #f5f5f5;
    }
    .ant-tabs-dropdown-menu-item-disabled,
    .ant-tabs-dropdown-menu-item-disabled:hover {
        color: rgba(0, 0, 0, 0.25);
        background: transparent;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        background: #fafafa;
        border: 1px solid #f0f0f0;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
        color: #1890ff;
        background: #fff;
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
        border-radius: 2px 2px 0 0;
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-bottom-color: #fff;
    }
    .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
        border-radius: 0 0 2px 2px;
    }
    .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-top-color: #fff;
    }
    .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
        border-radius: 2px 0 0 2px;
    }
    .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-right-color: #fff;
    }
    .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
        border-radius: 0 2px 2px 0;
    }
    .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-left-color: #fff;
    }
    .ant-tabs {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
        background: transparent;
        border: 0;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
        background: #fafafa;
        border: 1px solid #f0f0f0;
        border-radius: 2px 2px 0 0;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
        color: #40a9ff;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
        color: #096dd9;
    }
    .ant-tabs-ink-bar {
        // background: #72f8ff;
        background: #1890ff;
    }
    .ant-tabs-tab {
        background: transparent;
        border: 0;
    }
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active {
        color: #096dd9;
    }
    .ant-tabs-tab-remove {
        color: rgba(0, 0, 0, 0.45);
        background: transparent;
        border: none;
    }
    .ant-tabs-tab-remove:hover {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-tabs-tab:hover {
        color: #40a9ff;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #1890ff;
    }
    .ant-tabs-tab.ant-tabs-tab-disabled {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-tag {
        color: rgba(0, 0, 0, 0.85);
        background: #fafafa;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-tag,
    .ant-tag a,
    .ant-tag a:hover {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-tag-close-icon {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-tag-close-icon:hover {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-tag-has-color {
        border-color: transparent;
    }
    .ant-tag-has-color,
    .ant-tag-has-color a,
    .ant-tag-has-color a:hover,
    .ant-tag-has-color .anticon-close,
    .ant-tag-has-color .anticon-close:hover {
        color: #fff;
    }
    .ant-tag-checkable {
        background-color: transparent;
        border-color: transparent;
    }
    .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
        color: #1890ff;
    }
    .ant-tag-checkable:active,
    .ant-tag-checkable-checked {
        color: #fff;
    }
    .ant-tag-checkable-checked {
        background-color: #1890ff;
    }
    .ant-tag-checkable:active {
        background-color: #096dd9;
    }
    .ant-tag-pink {
        color: #eb2f96;
        background: #fff0f6;
        border-color: #ffadd2;
    }
    .ant-tag-pink-inverse {
        color: #fff;
        background: #eb2f96;
        border-color: #eb2f96;
    }
    .ant-tag-magenta {
        color: #eb2f96;
        background: #fff0f6;
        border-color: #ffadd2;
    }
    .ant-tag-magenta-inverse {
        color: #fff;
        background: #eb2f96;
        border-color: #eb2f96;
    }
    .ant-tag-red {
        color: #f5222d;
        background: transparent;
        border-color: #f5222d;
    }
    .ant-tag-red-inverse {
        color: #fff;
        background: #f5222d;
        border-color: #f5222d;
    }
    .ant-tag-volcano {
        color: #fa541c;
        background: #fff2e8;
        border-color: #ffbb96;
    }
    .ant-tag-volcano-inverse {
        color: #fff;
        background: #fa541c;
        border-color: #fa541c;
    }
    .ant-tag-orange {
        color: #fa8c16;
        background: #fff7e6;
        border-color: #ffd591;
    }
    .ant-tag-orange-inverse {
        color: #fff;
        background: #fa8c16;
        border-color: #fa8c16;
    }
    .ant-tag-yellow {
        color: #fadb14;
        background: #feffe6;
        border-color: #fffb8f;
    }
    .ant-tag-yellow-inverse {
        color: #fff;
        background: #fadb14;
        border-color: #fadb14;
    }
    .ant-tag-gold {
        color: #faad14;
        background: #fffbe6;
        border-color: #ffe58f;
    }
    .ant-tag-gold-inverse {
        color: #fff;
        background: #faad14;
        border-color: #faad14;
    }
    .ant-tag-cyan {
        color: #13c2c2;
        background: #e6fffb;
        border-color: #87e8de;
    }
    .ant-tag-cyan-inverse {
        color: #fff;
        background: #13c2c2;
        border-color: #13c2c2;
    }
    .ant-tag-lime {
        color: #a0d911;
        background: #fcffe6;
        border-color: #eaff8f;
    }
    .ant-tag-lime-inverse {
        color: #fff;
        background: #a0d911;
        border-color: #a0d911;
    }
    .ant-tag-green {
        color: #52c41a;
        background: #f6ffed;
        border-color: #b7eb8f;
    }
    .ant-tag-green-inverse {
        color: #fff;
        background: #52c41a;
        border-color: #52c41a;
    }
    .ant-tag-blue {
        color: #1890ff;
        background: #e6f7ff;
        border-color: #91d5ff;
    }
    .ant-tag-blue-inverse {
        color: #fff;
        background: #1890ff;
        border-color: #1890ff;
    }
    .ant-tag-geekblue {
        color: #2f54eb;
        background: #fff2f0;
        border-color: #adc6ff;
    }
    .ant-tag-geekblue-inverse {
        color: #fff;
        background: #2f54eb;
        border-color: #2f54eb;
    }
    .ant-tag-purple {
        color: #722ed1;
        background: #fff1f0;
        border-color: #d3adf7;
    }
    .ant-tag-purple-inverse {
        color: #fff;
        background: #722ed1;
        border-color: #722ed1;
    }
    .ant-tag-success {
        color: #52c41a;
        background: #f6ffed;
        border-color: #b7eb8f;
    }
    .ant-tag-processing {
        color: #1890ff;
        background: #e6f7ff;
        border-color: #91d5ff;
    }
    .ant-tag-error {
        color: #f5222d;
        background: #69c0ff;
        border-color: #ffa39e;
    }
    .ant-tag-warning {
        color: #fa8c16;
        background: #fff7e6;
        border-color: #ffd591;
    }
    .ant-timeline {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-timeline-item-tail {
        border-left: 2px solid #f0f0f0;
    }
    .ant-timeline-item-pending .ant-timeline-item-head {
        background-color: transparent;
    }
    .ant-timeline-item-head {
        background-color: #fff;
        border: 2px solid transparent;
        border-radius: 100px;
    }
    .ant-timeline-item-head-blue {
        color: #1890ff;
        border-color: #1890ff;
    }
    .ant-timeline-item-head-red {
        color: #ff4d4f;
        border-color: #ff4d4f;
    }
    .ant-timeline-item-head-green {
        color: #52c41a;
        border-color: #52c41a;
    }
    .ant-timeline-item-head-gray {
        color: rgba(0, 0, 0, 0.25);
        border-color: rgba(0, 0, 0, 0.25);
    }
    .ant-timeline-item-head-custom {
        border: 0;
        border-radius: 0;
    }
    .ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
        border-left: 2px dotted #f0f0f0;
    }
    .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
        border-left: 2px dotted #f0f0f0;
    }
    .ant-timeline-rtl .ant-timeline-item-tail {
        border-right: 2px solid #f0f0f0;
        border-left: none;
    }
    .ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
        border-right: 2px dotted #f0f0f0;
        border-left: none;
    }
    .ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
        border-right: 2px dotted #f0f0f0;
        border-left: none;
    }
    .ant-tooltip {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-tooltip-inner {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 2px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .ant-tooltip-arrow {
        background: transparent;
    }
    .ant-tooltip-arrow-content {
        background-color: rgba(0, 0, 0, 0.75);
    }
    .ant-tooltip-placement-top .ant-tooltip-arrow-content,
    .ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
    .ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    }
    .ant-tooltip-placement-right .ant-tooltip-arrow-content,
    .ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
    .ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
        box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
    }
    .ant-tooltip-placement-left .ant-tooltip-arrow-content,
    .ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
    .ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
        box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
    }
    .ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
    .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
    .ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
        box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
    }
    .ant-tooltip-pink .ant-tooltip-inner {
        background-color: #eb2f96;
    }
    .ant-tooltip-pink .ant-tooltip-arrow-content {
        background-color: #eb2f96;
    }
    .ant-tooltip-magenta .ant-tooltip-inner {
        background-color: #eb2f96;
    }
    .ant-tooltip-magenta .ant-tooltip-arrow-content {
        background-color: #eb2f96;
    }
    .ant-tooltip-red .ant-tooltip-inner {
        background-color: #f5222d;
    }
    .ant-tooltip-red .ant-tooltip-arrow-content {
        background-color: #f5222d;
    }
    .ant-tooltip-volcano .ant-tooltip-inner {
        background-color: #fa541c;
    }
    .ant-tooltip-volcano .ant-tooltip-arrow-content {
        background-color: #fa541c;
    }
    .ant-tooltip-orange .ant-tooltip-inner {
        background-color: #fa8c16;
    }
    .ant-tooltip-orange .ant-tooltip-arrow-content {
        background-color: #fa8c16;
    }
    .ant-tooltip-yellow .ant-tooltip-inner {
        background-color: #fadb14;
    }
    .ant-tooltip-yellow .ant-tooltip-arrow-content {
        background-color: #fadb14;
    }
    .ant-tooltip-gold .ant-tooltip-inner {
        background-color: #faad14;
    }
    .ant-tooltip-gold .ant-tooltip-arrow-content {
        background-color: #faad14;
    }
    .ant-tooltip-cyan .ant-tooltip-inner {
        background-color: #13c2c2;
    }
    .ant-tooltip-cyan .ant-tooltip-arrow-content {
        background-color: #13c2c2;
    }
    .ant-tooltip-lime .ant-tooltip-inner {
        background-color: #a0d911;
    }
    .ant-tooltip-lime .ant-tooltip-arrow-content {
        background-color: #a0d911;
    }
    .ant-tooltip-green .ant-tooltip-inner {
        background-color: #52c41a;
    }
    .ant-tooltip-green .ant-tooltip-arrow-content {
        background-color: #52c41a;
    }
    .ant-tooltip-blue .ant-tooltip-inner {
        background-color: #1890ff;
    }
    .ant-tooltip-blue .ant-tooltip-arrow-content {
        background-color: #1890ff;
    }
    .ant-tooltip-geekblue .ant-tooltip-inner {
        background-color: #2f54eb;
    }
    .ant-tooltip-geekblue .ant-tooltip-arrow-content {
        background-color: #2f54eb;
    }
    .ant-tooltip-purple .ant-tooltip-inner {
        background-color: #722ed1;
    }
    .ant-tooltip-purple .ant-tooltip-arrow-content {
        background-color: #722ed1;
    }
    .ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
        border: 0;
        border-radius: 0;
    }
    .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
        background: #fafafa;
    }
    .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
        border-bottom: 1px solid #f0f0f0;
    }
    .ant-transfer-customize-list .ant-input[disabled] {
        background-color: transparent;
    }
    .ant-transfer {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-transfer-disabled .ant-transfer-list {
        background: #f5f5f5;
    }
    .ant-transfer-list {
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-transfer-list-search-action {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-transfer-list-search-action .anticon {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-transfer-list-search-action .anticon:hover {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-transfer-list-header {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-bottom: 1px solid #f0f0f0;
        border-radius: 2px 2px 0 0;
    }
    .ant-transfer-list-content-item-remove {
        color: #1890ff;
        color: #d9d9d9;
    }
    .ant-transfer-list-content-item-remove:focus,
    .ant-transfer-list-content-item-remove:hover {
        color: #40a9ff;
    }
    .ant-transfer-list-content-item-remove:active {
        color: #096dd9;
    }
    .ant-transfer-list-content-item-remove:hover {
        color: #40a9ff;
    }
    .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
        background-color: #f5f5f5;
    }
    .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
        background-color: #dcf4ff;
    }
    .ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
        background: transparent;
    }
    .ant-transfer-list-content-item-checked {
        background-color: #e6f7ff;
    }
    .ant-transfer-list-content-item-disabled {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-transfer-list-pagination {
        border-top: 1px solid #f0f0f0;
    }
    .ant-transfer-list-body-not-found {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-transfer-list-footer {
        border-top: 1px solid #f0f0f0;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
        background: #f5f5f5;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
        border-radius: 0;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
        background: transparent;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
        color: #fff;
        background: transparent;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
        background: #1890ff;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
        color: #fff;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
        color: #fff;
        background: transparent;
    }
    .ant-tree-checkbox {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
    .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
    .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
        border-color: #1890ff;
    }
    .ant-tree-checkbox-checked::after {
        border: 1px solid #1890ff;
        border-radius: 2px;
    }
    .ant-tree-checkbox-inner {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        border-collapse: separate;
    }
    .ant-tree-checkbox-inner::after {
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
    }
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
    }
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: #1890ff;
        border-color: #1890ff;
    }
    .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
        border-color: rgba(0, 0, 0, 0.25);
    }
    .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
        background-color: #f5f5f5;
        border-color: #d9d9d9 !important;
    }
    .ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
        border-color: #f5f5f5;
        border-collapse: separate;
    }
    .ant-tree-checkbox-disabled + span {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-tree-checkbox-wrapper {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-tree-checkbox-group {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
        background-color: #fff;
        border-color: #d9d9d9;
    }
    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        background-color: #1890ff;
        border: 0;
    }
    .ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: rgba(0, 0, 0, 0.25);
    }
    .ant-tree {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-radius: 2px;
    }
    .ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
        background: #e6f7ff;
    }
    .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
        background: transparent;
    }
    .ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
        background: #f5f5f5;
    }
    .ant-tree .ant-tree-switcher-loading-icon {
        color: #1890ff;
    }
    .ant-tree .ant-tree-switcher-leaf-line::before {
        border-left: 1px solid #d9d9d9;
    }
    .ant-tree .ant-tree-switcher-leaf-line::after {
        border-bottom: 1px solid #d9d9d9;
    }
    .ant-tree .ant-tree-node-content-wrapper {
        color: inherit;
        background: transparent;
        border-radius: 2px;
    }
    .ant-tree .ant-tree-node-content-wrapper:hover {
        background-color: #f5f5f5;
    }
    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: #bae7ff;
    }
    .ant-tree-node-content-wrapper[draggable='true'] {
        border-top: 2px transparent solid;
        border-bottom: 2px transparent solid;
    }
    .ant-tree .ant-tree-treenode.drag-over > [draggable] {
        color: white;
        background-color: #1890ff;
    }
    .ant-tree .ant-tree-treenode.drag-over-gap-top > [draggable] {
        border-top-color: #1890ff;
    }
    .ant-tree .ant-tree-treenode.drag-over-gap-bottom > [draggable] {
        border-bottom-color: #1890ff;
    }
    .ant-tree-show-line .ant-tree-indent-unit:first-child::after {
        border-right: 1px solid #d9d9d9;
    }
    .ant-tree-show-line .ant-tree-indent-unit::before {
        border-right: 1px solid #d9d9d9;
    }
    .ant-tree-show-line .ant-tree-switcher {
        background: #fff;
    }
    .ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
        border-right: none;
        border-left: 1px solid #d9d9d9;
    }
    .ant-select-tree-checkbox {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
    .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
    .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
        border-color: #1890ff;
    }
    .ant-select-tree-checkbox-checked::after {
        border: 1px solid #1890ff;
        border-radius: 2px;
    }
    .ant-select-tree-checkbox-inner {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        border-collapse: separate;
    }
    .ant-select-tree-checkbox-inner::after {
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
    }
    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
    }
    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
        background-color: #1890ff;
        border-color: #1890ff;
    }
    .ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
        border-color: rgba(0, 0, 0, 0.25);
    }
    .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
        background-color: #f5f5f5;
        border-color: #d9d9d9 !important;
    }
    .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
        border-color: #f5f5f5;
        border-collapse: separate;
    }
    .ant-select-tree-checkbox-disabled + span {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-select-tree-checkbox-wrapper {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-select-tree-checkbox-group {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
        background-color: #fff;
        border-color: #d9d9d9;
    }
    .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
        background-color: #1890ff;
        border: 0;
    }
    .ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: rgba(0, 0, 0, 0.25);
    }
    .ant-tree-select-dropdown .ant-select-tree {
        border-radius: 0;
    }
    .ant-select-tree {
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-radius: 2px;
    }
    .ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
        background: #e6f7ff;
    }
    .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
        background: transparent;
    }
    .ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
        background: #f5f5f5;
    }
    .ant-select-tree .ant-select-tree-switcher-loading-icon {
        color: #1890ff;
    }
    .ant-select-tree .ant-select-tree-switcher-leaf-line::before {
        border-left: 1px solid #d9d9d9;
    }
    .ant-select-tree .ant-select-tree-switcher-leaf-line::after {
        border-bottom: 1px solid #d9d9d9;
    }
    .ant-select-tree .ant-select-tree-node-content-wrapper {
        color: inherit;
        background: transparent;
        border-radius: 2px;
    }
    .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
        background-color: #f5f5f5;
    }
    .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
        background-color: #bae7ff;
    }
    .ant-select-tree-node-content-wrapper[draggable='true'] {
        border-top: 2px transparent solid;
        border-bottom: 2px transparent solid;
    }
    .ant-select-tree .ant-select-tree-treenode.drag-over > [draggable] {
        color: white;
        background-color: #1890ff;
    }
    .ant-select-tree .ant-select-tree-treenode.drag-over-gap-top > [draggable] {
        border-top-color: #1890ff;
    }
    .ant-select-tree .ant-select-tree-treenode.drag-over-gap-bottom > [draggable] {
        border-bottom-color: #1890ff;
    }
    .ant-select-tree-show-line .ant-select-tree-indent-unit:first-child::after {
        border-right: 1px solid #d9d9d9;
    }
    .ant-select-tree-show-line .ant-select-tree-indent-unit::before {
        border-right: 1px solid #d9d9d9;
    }
    .ant-select-tree-show-line .ant-select-tree-switcher {
        background: #fff;
    }
    .ant-typography {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-typography.ant-typography-secondary {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-typography.ant-typography-success {
        color: #52c41a;
    }
    .ant-typography.ant-typography-warning {
        color: #faad14;
    }
    .ant-typography.ant-typography-danger {
        color: #ff4d4f;
    }
    .ant-typography.ant-typography-disabled {
        color: rgba(0, 0, 0, 0.25);
    }
    h1.ant-typography,
    .ant-typography h1 {
        color: rgba(0, 0, 0, 0.85);
    }
    h2.ant-typography,
    .ant-typography h2 {
        color: rgba(0, 0, 0, 0.85);
    }
    h3.ant-typography,
    .ant-typography h3 {
        color: rgba(0, 0, 0, 0.85);
    }
    h4.ant-typography,
    .ant-typography h4 {
        color: rgba(0, 0, 0, 0.85);
    }
    h5.ant-typography,
    .ant-typography h5 {
        color: rgba(0, 0, 0, 0.85);
    }
    a.ant-typography,
    .ant-typography a {
        color: #1890ff;
    }
    a.ant-typography:focus,
    .ant-typography a:focus,
    a.ant-typography:hover,
    .ant-typography a:hover {
        color: #40a9ff;
    }
    a.ant-typography:active,
    .ant-typography a:active {
        color: #096dd9;
    }
    a.ant-typography[disabled],
    .ant-typography a[disabled] {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-typography code {
        background: rgba(150, 150, 150, 0.1);
        border: 1px solid rgba(100, 100, 100, 0.2);
        border-radius: 3px;
    }
    .ant-typography kbd {
        background: rgba(150, 150, 150, 0.06);
        border: 1px solid rgba(100, 100, 100, 0.2);
        border-bottom-width: 2px;
        border-radius: 3px;
    }
    .ant-typography mark {
        background-color: #ffe58f;
    }
    .ant-typography-expand,
    .ant-typography-edit,
    .ant-typography-copy {
        color: #1890ff;
    }
    .ant-typography-expand:focus,
    .ant-typography-edit:focus,
    .ant-typography-copy:focus,
    .ant-typography-expand:hover,
    .ant-typography-edit:hover,
    .ant-typography-copy:hover {
        color: #40a9ff;
    }
    .ant-typography-expand:active,
    .ant-typography-edit:active,
    .ant-typography-copy:active {
        color: #096dd9;
    }
    .ant-typography-copy-success,
    .ant-typography-copy-success:hover,
    .ant-typography-copy-success:focus {
        color: #52c41a;
    }
    .ant-typography-edit-content-confirm {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-upload {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-upload.ant-upload-select-picture-card {
        background-color: #fafafa;
        border: 1px dashed #d9d9d9;
        border-radius: 2px;
    }
    .ant-upload.ant-upload-select-picture-card:hover {
        border-color: #1890ff;
    }
    .ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
        border-color: #d9d9d9;
    }
    .ant-upload.ant-upload-drag {
        background: #fafafa;
        border: 1px dashed #d9d9d9;
        border-radius: 2px;
    }
    .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
        border-color: #096dd9;
    }
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: #40a9ff;
    }
    .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
        color: #40a9ff;
    }
    .ant-upload.ant-upload-drag p.ant-upload-text {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-upload.ant-upload-drag p.ant-upload-hint {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-upload.ant-upload-drag .anticon-plus {
        color: rgba(0, 0, 0, 0.25);
    }
    .ant-upload.ant-upload-drag .anticon-plus:hover {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-upload.ant-upload-drag:hover .anticon-plus {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-upload-list {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-upload-list-item-card-actions .anticon {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-upload-list-item-info .anticon-loading .anticon,
    .ant-upload-list-item-info .ant-upload-text-icon .anticon {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-upload-list-item .anticon-close {
        color: rgba(0, 0, 0, 0.45);
    }
    .ant-upload-list-item .anticon-close:hover {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-upload-list-item:hover .ant-upload-list-item-info {
        background-color: #f5f5f5;
    }
    .ant-upload-list-item-error,
    .ant-upload-list-item-error .ant-upload-text-icon > .anticon,
    .ant-upload-list-item-error .ant-upload-list-item-name {
        color: #ff4d4f;
    }
    .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
        color: #ff4d4f;
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-upload-list-picture .ant-upload-list-item:hover,
    .ant-upload-list-picture-card .ant-upload-list-item:hover {
        background: transparent;
    }
    .ant-upload-list-picture .ant-upload-list-item-error,
    .ant-upload-list-picture-card .ant-upload-list-item-error {
        border-color: #ff4d4f;
    }
    .ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
    .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
        background: transparent;
    }
    .ant-upload-list-picture .ant-upload-list-item-uploading,
    .ant-upload-list-picture-card .ant-upload-list-item-uploading {
        border-style: dashed;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info::before {
        background-color: rgba(0, 0, 0, 0.5);
    }
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
        color: #fff;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
        background-color: #fafafa;
    }
    .ant-upload-list .ant-upload-success-icon {
        color: #52c41a;
    }
    .theme-card .toggle {
        background: white;
        border: 1px solid #e8e8e8;
    }
    body *::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
        border-radius: 10px;
    }
    body *::-webkit-scrollbar {
        background-color: #f5f5f5;
    }
    body *::-webkit-scrollbar-thumb {
        background-color: #696969;
        border-radius: 10px;
        background-image: -webkit-linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.5) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0.5) 75%,
            transparent 75%,
            transparent
        );
    }
    .secondary-color {
        color: #1890ff;
    }
    .app-header .nav-icon {
        color: #fff;
    }
    .nav-drawer .ant-drawer-content {
        background-color: #001529;
    }
    .nav-drawer .ant-drawer-content .ant-drawer-body .logo {
        color: #ffffff;
    }
    .component-preview h4 {
        color: rgba(0, 0, 0, 0.85);
    }
    .component-preview .colors .color-row .color-item {
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.85);
    }
    .component-preview .colors .color-row .color-item.primary {
        background: #1890ff;
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.primary-custom-var {
        background: var(--PC);
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.success {
        background: #52c41a;
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.info {
        background: #1890ff;
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.warning {
        background: #faad14;
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.error {
        background: #ff4d4f;
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.highlight {
        background: #ff4d4f;
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.body-background {
        background: #fff;
    }
    .component-preview .colors .color-row .color-item.component-background {
        background: #fff;
    }
    .component-preview .colors .color-row .color-item.layout-header-background {
        background: #001529;
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.layout-body-background {
        background: #f0f2f5;
        border: 1px solid #d9d9d9;
    }
    .component-preview .colors .color-row .color-item.layout-footer-background {
        background: #f0f2f5;
        border: 1px solid #d9d9d9;
    }
    .component-preview .colors .color-row .color-item.border-color-base {
        background: #d9d9d9;
    }
    .component-preview .colors .color-row .color-item.border-color-split {
        background: #f0f0f0;
    }
    .component-preview .colors .color-row .color-item.link-color {
        background: #1890ff;
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.disabled-color {
        background: rgba(0, 0, 0, 0.25);
    }
    .component-preview .colors .color-row .color-item.disabled-bg {
        background: #f5f5f5;
    }
    .component-preview .colors .color-row .color-item.processing-color {
        background: #1890ff;
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.icon-color {
        background: inherit;
        border: 1px solid #d9d9d9;
    }
    .component-preview .colors .color-row .color-item.icon-color-hover {
        background: rgba(0, 0, 0, 0.75);
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.heading-color {
        background: rgba(0, 0, 0, 0.85);
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.text-color {
        background: rgba(0, 0, 0, 0.85);
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.text-color-secondary {
        background: rgba(0, 0, 0, 0.45);
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.text-selection-bg {
        background: #1890ff;
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.text-color-inverse {
        background: rgba(0, 0, 0, 0.85) inverse;
    }
    .component-preview .colors .color-row .color-item.text-color-dark {
        background: rgba(0, 0, 0, 0.85) dark;
    }
    .component-preview .colors .color-row .color-item.text-color-secondary-dark {
        background: rgba(0, 0, 0, 0.45) dark;
    }
    .dashboard {
        color: rgba(0, 0, 0, 0.85);
    }
    .secondary {
        color: #1890ff;
    }
    .components .component-row .component-col .badge-square {
        border-radius: 4px;
    }
    .app {
        background-color: #fff;
    }
    .app .ant-layout-header .logo {
        color: #ffffff;
    }
    .app > .ant-layout-content .ant-layout-sider .choose-theme label {
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .app > .ant-layout-content .ant-layout-sider .theme-heading {
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .app > .ant-layout-content .ant-layout-sider .color-row .color-name {
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .logo h1 {
        color: rgba(0, 0, 0, 0.85) inverse;
    }
    .components .component-row .component-col .badge-square {
        border-radius: 4px;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: #1890ff;
    }
    .section-content {
        background: linear-gradient(#e2efff, #fff);
    }

    .info-table {
        border-spacing: 0;
    }

    .info-table td:first-child {
        width: 150px;
    }

    .info-table td {
        vertical-align: top;
        padding-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid #f2f2f2;
    }

    .info-table td:first-child {
        color: rgba(0, 0, 0, 0.5);
    }

    .info-table td:nth-child(2) {
        color: rgba(0, 0, 0, 0.85);
    }

    .info-table-dark {
        border-spacing: 0;
    }

    .info-table-dark td:first-child {
        width: 150px;
    }

    .info-table-dark td {
        vertical-align: top;
        padding-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .info-table-dark td:first-child {
        color: rgba(255, 255, 255, 0.5);
    }

    .info-table-dark td:nth-child(2) {
        color: rgba(255, 255, 255, 0.85);
    }

    .info-table-stats {
        border-spacing: 0;
    }

    .info-table-stats td {
        vertical-align: top;
        padding-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid #f2f2f2;
        font-size: 17px;
    }

    .info-table-stats-dark {
        border-spacing: 0;
    }

    .info-table-stats-dark td {
        vertical-align: top;
        padding-right: 20px;
        padding-top: 2px;
        padding-bottom: 2px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        font-size: 17px;
    }

    .info-table-stats-dark tr:last-child td {
        border-bottom: none;
    }

    .hover-list .ant-list-item {
        padding: 10px 20px;
        cursor: pointer;
        transition: all 150ms ease;
    }

    .hover-list .ant-list-item:hover {
        background-color: #e8e8e8;
    }

    .tabs-bg .ant-tabs-ink-bar {
        background-color: #72f8ff;
    }

    .tabs-bg2 .ant-tabs-ink-bar {
        background-color: #1890ff;
    }

    .tabs-bg-orange .ant-tabs-ink-bar {
        background-color: #ffab28;
    }

    .player-search-clear.ant-select-auto-complete.ant-select .ant-select-selection__placeholder,
    .player-search-clear.ant-select-selection__placeholder ::placeholder {
        color: #fff !important;
    }

    .player-search-clear .ant-select-selection {
        background: none !important;
        color: #fff;
    }

    .player-search-clear {
        background-color: transparent;
        /* max-width: 200px; */
    }

    .player-search-clear.ant-select-auto-complete.ant-select .ant-input {
        // border: none;
    }

    .player-search-clear input {
        background-color: transparent;
        color: #fff;
    }

    .player-search-clear ::placeholder {
        color: rgba(255, 255, 255, 0.6) !important;
        opacity: 1;
    }

    .player-search-clear .ant-input-search-icon {
        color: #fff;
    }

    .ant-select-selection--multiple .ant-select-selection__choice {
        background-color: #fafafa;
        border: 1px solid #e8e8e8;
    }

    .ant-select-selection--multiple .ant-select-selection__choice__remove {
        color: rgba(0, 0, 0, 0.45);
    }
}
