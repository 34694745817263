.podium {
	display: flex;
	/* margin-top: 20px; */
	/* height: 170px; */
}

.podium .place {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.podium .place + .place {
	/* margin-left: 1px; */
}

.podium .place .participant {
	text-align: center;
	width: 100%;
	flex: 1;
	align-items: center;
	display: flex;
	justify-content: center;
	font-size: 16px;
}

.podium .place .participant .info {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.podium .place .participant .name {
	margin-top: 10px;
	margin-bottom: 5px;
}

.podium .place .base {
	background-color: black;
	vertical-align: bottom;
	position: relative;
	display: inline-block;
	width: 100%;
}

.podium .place .number {
	padding: 10px 0px;
	position: relative;
}

.podium .place .number {
	color: #fff;
	text-align: center;
	width: 100%;
	background-color: #40464d;
}

/* .podium .place:first-child .number{
	border-left: 1px solid #ddd;
}

.podium .place:last-child .number{
	border-right: 1px solid #ddd;
} */

.podium .place.position1 .base {
	height: 50px;
	background-color: #fff0b3;
	background: linear-gradient(180deg, #fff0b3, #ffcc00);
}

.podium .place.position2 .base {
	height: 25px;
	background-color: #d3dce4;
	background: linear-gradient(180deg, #dee1e2, #b0bec5);
}

.podium .place.position2 .participant .info {
	margin-top: 10px;
}

.podium .place.position3 .base {
	height: 10px;
	background-color: #ffcd83;
	background: linear-gradient(180deg, #ffcd83, #ff9800);
}

/* .podium .place:nth-child(4).position3 .base {
	height: 10px;
	background: #ffcd83 !important;
	background: linear-gradient(180deg, #ffcd83, #ff9800);
} */

.podium .place.position3 .participant .info {
	margin-top: 30px;
}
