.pool-table {
    margin-top: 0.5em;
    position: relative;
    margin: auto;
    /* width: 100%; */
    /* width: 350px; */
    /* padding: 22px; */
    /* min-height: 160px; */
    /* height: 190px; */
    transition: opacity 300ms ease;
    display: flex;
    flex-direction: column;
    /* align-items: stretch; */
    /* align-content: center; */
    justify-content: center;
}

.pool-table > a {
    opacity: 1;
    transition: opacity 300ms ease;
}

.pool-table .ended {
    opacity: 0.7;
}

.pool-table .player-info,
.pool-table .team-info {
    margin: 0px;
    color: white;
    font-weight: 600;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.4em;
}

.pool-table.large .player-info,
.pool-table.large .team-info {
    font-size: 22px;
}

.pool-table .match-info {
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 1;
    display: flex;
    /* flex-direction: column; */
    /* height: 70%; */
    justify-content: center;
}

.pool-table .match-link {
    display: flex;
    align-items: center;
}

.pool-table.large .item {
    width: 100%;
}

.pool-table .item {
    width: 100%;
    padding: 0px 10%;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.pool-table .item + .item {
    margin-top: 5px;
}

.pool-table .item.winning {
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.4));
}

.pool-table .item.winner {
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.4));
    /* background-color: #999FB3; */
}

.pool-table .item .content {
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
}

.pool-table .item .content .ui.avatar.image {
    margin-right: 1em;
}

.pool-table.large .item .points {
    font-size: 2em;
    padding: 5px;
    min-width: 45px;
}

.pool-table .item .points {
    margin: 10px 0px 10px 10px;
    /* min-width: 40px; */
    min-width: 40px;
    font-size: 1.5em;
    font-weight: 600;
    color: white;
    white-space: nowrap;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    /* font-family: 'din_medium', 'Roboto', sans-serif; */
}

.pool-table.large .item .score {
    font-size: 2em;
    padding: 5px;
    min-width: 45px;
}

.pool-table .item .score {
    margin: 10px 0px 10px 10px;
    /* min-width: 40px; */
    min-width: 40px;
    font-size: 1.5em;
    font-weight: 600;
    color: white;
    white-space: nowrap;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    text-align: center;
    /* font-family: 'din_medium', 'Roboto', sans-serif; */
}

.pool-table.blinking h3 {
    animation: blinkingTable 1.2s infinite;
}

@keyframes blinkingTable {
    0% {
        color: #fff;
        background-color: none;
        border-color: none;
    }
    49% {
        color: #fff;
        background-color: none;
        border-color: none;
    }
    60% {
        color: transparent;
        background-color: none;
        border-color: none;
    }
    99% {
        color: transparent;
        background-color: none;
        border-color: none;
    }
    100% {
        color: #fff;
        background-color: none;
        border-color: none;
    }
}
